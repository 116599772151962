import { animated, config, useSpring } from "react-spring";
import Section from "../../../components/layouts/section/Section";
import PremiumButton from "../../../modules/premium-button/PremiumButton";
import styles from "../HeroImageSection.module.scss";

type Props = {};

const ID_PREFIX = "pricing-premium-hero-image-section";

const PricingPremiumHeroImageSection = ({}: Props) => {
  const anim = useSpring({
    from: {
      opacity: 0,
      x: 500,
    },
    to: {
      opacity: 1,
      x: 0,
    },
    config: config.default,
  });

  const anim2 = useSpring({
    from: {
      opacity: 0,
      x: -200,
    },
    to: {
      opacity: 1,
      x: 0,
    },
    config: config.default,
    delay: 300,
  });

  const anim3 = useSpring({
    from: {
      opacity: 0,
      x: -200,
    },
    to: {
      opacity: 1,
      x: 0,
    },
    config: config.default,
    delay: 600,
  });

  const anim4 = useSpring({
    from: {
      x: 100,
      opacity: 0,
    },
    to: {
      x: 0,
      opacity: 1,
    },
    config: config.default,
    delay: 1200,
  });

  const handleUpgrade = (e: any) => {
    console.log(e);
  };
  return (
    <Section
      id={`${ID_PREFIX}-main`}
      pogrId={`${ID_PREFIX}-main`}
      style={{
        background: "linear-gradient(180deg, #101423 0%, #151A2A 100%)",
      }}
    >
      <div
        id={`${ID_PREFIX}-text-overlay-premium`}
        data-pogr-id={`${ID_PREFIX}-text-overlay-premium`}
        className={styles.textOverlayPremium}
      >
        <div
          id={`${ID_PREFIX}-text-box`}
          data-pogr-id={`${ID_PREFIX}-text-box`}
          className={styles.textBox}
        >
          <animated.div
            id={`${ID_PREFIX}-animated-title`}
            data-pogr-id={`${ID_PREFIX}-animated-title`}
            style={anim2}
            className={styles.titlePremium}
          >
            <div
              id={`${ID_PREFIX}-title-text-pogr`}
              data-pogr-id={`${ID_PREFIX}-title-text-pogr`}
              className={styles.titleText}
            >
              POGR
            </div>
            <div
              id={`${ID_PREFIX}-title-text-premium`}
              data-pogr-id={`${ID_PREFIX}-title-text-premium`}
              className={styles.titleText + " " + styles.premiumText}
            >
              Premium
            </div>
          </animated.div>
          <animated.p
            id={`${ID_PREFIX}-animated-description`}
            data-pogr-id={`${ID_PREFIX}-animated-description`}
            style={anim3}
            className={styles.description}
          >
            Get more out of your POGR experience by upgrading to premium. Gain
            access to the World League, a wider range of profile customization
            and something else.
          </animated.p>
          <animated.div
            id={`${ID_PREFIX}-animated-button-container`}
            data-pogr-id={`${ID_PREFIX}-animated-button-container`}
            style={anim4}
            className={styles.premiumButtonContainer}
          >
            <PremiumButton
              buttonId="pricing-premium"
              pogrId="pricing-premium"
              handleOnClick={handleUpgrade}
            />
          </animated.div>
        </div>
        <div
          id={`${ID_PREFIX}-image-box`}
          data-pogr-id={`${ID_PREFIX}-image-box`}
          className={styles.imageBox}
        >
          <animated.div
            id={`${ID_PREFIX}-image-container`}
            data-pogr-id={`${ID_PREFIX}-image-container`}
            className={styles.imageContainer}
            style={anim}
          >
            <img
              id={`${ID_PREFIX}-gold-badge-image`}
              data-pogr-id={`${ID_PREFIX}-gold-badge-image`}
              src={"/static/hero-images/pricing-premium.webp"}
              width="100%"
              height="100%"
              alt="Hero icon badge for premium pricing"
            />
          </animated.div>
        </div>
      </div>
    </Section>
  );
};

export default PricingPremiumHeroImageSection;
