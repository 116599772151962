import React from "react";
import Section from "../../components/layouts/section/Section";
import { Button } from "@mui/material";
import styles from "./OurToolboxSection.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { faFile } from "@fortawesome/free-regular-svg-icons";

type Props = {};

const ID_PREFIX = "sdk-tool-box-section";

function OurToolboxSection({}: Props) {
  return (
    <Section
      id={`${ID_PREFIX}-main`}
      pogrId={`${ID_PREFIX}-main`}
      style={{ backgroundColor: "#132439" }}
    >
      <div
        id={`${ID_PREFIX}-container`}
        data-pogr-id={`${ID_PREFIX}-container`}
        className={styles.container}
      >
        <div
          id={`${ID_PREFIX}-toolbox-text-container`}
          data-pogr-id={`${ID_PREFIX}-toolbox-text-container`}
        >
          <h2
            id={`${ID_PREFIX}-toolbox-text`}
            data-pogr-id={`${ID_PREFIX}-toolbox-text`}
          >
            Our toolbox
          </h2>
        </div>
        <div
          id={`${ID_PREFIX}-tool-block-row`}
          data-pogr-id={`${ID_PREFIX}-tool-block-row`}
          className={styles.toolBlockRow}
        >
          <div
            id={`${ID_PREFIX}-tool-block-sdk`}
            data-pogr-id={`${ID_PREFIX}-tool-block-sdk`}
            className={styles.toolBlock}
          >
            <div
              id={`${ID_PREFIX}-tool-block-sdk-image-container`}
              data-pogr-id={`${ID_PREFIX}-tool-block-sdk-image-container`}
            >
              <img
                id={`${ID_PREFIX}-tool-block-sdk-image`}
                data-pogr-id={`${ID_PREFIX}-tool-block-sdk-image`}
                src="/static/icons/SDKIcon.svg"
                alt="sdk"
              />
            </div>
            <div
              id={`${ID_PREFIX}-tool-block-sdk-text-container`}
              data-pogr-id={`${ID_PREFIX}-tool-block-sdk-text-container`}
            >
              <h3
                id={`${ID_PREFIX}-tool-block-sdk-text`}
                data-pogr-id={`${ID_PREFIX}-tool-block-sdk-text`}
              >
                SDK
              </h3>
            </div>
            <div
              id={`${ID_PREFIX}-tool-block-sdk-description-container`}
              data-pogr-id={`${ID_PREFIX}-tool-block-sdk-description-container`}
            >
              <span
                id={`${ID_PREFIX}-tool-block-sdk-descrpition`}
                data-pogr-id={`${ID_PREFIX}-tool-block-sdk-descrpition`}
                className={styles.description}
              >
                Custom engine? Drop in our open-source SDK for uploading,
                downloading, & unzip flows via C/C++ function calls.
              </span>
            </div>
            <div
              id={`${ID_PREFIX}-how-it-works-button-container`}
              data-pogr-id={`${ID_PREFIX}-how-it-works-button-container`}
            >
              <Button
                id={`${ID_PREFIX}-tool-block-api-what-we-provide-button`}
                data-pogr-id={`${ID_PREFIX}-tool-block-api-what-we-provide-button`}
                variant="contained"
                color="secondary"
                endIcon={<FontAwesomeIcon icon={faAngleRight} />}
              >
                Coming soon
              </Button>
            </div>
            <div
              id={`${ID_PREFIX}-documentation-button-container`}
              data-pogr-id={`${ID_PREFIX}-documentation-button-container`}
            >
              <Button
                id={`${ID_PREFIX}-documentation-button`}
                data-pogr-id={`${ID_PREFIX}-documentation-button`}
                variant="text"
                startIcon={<FontAwesomeIcon width={12} icon={faFile} />}
                disabled
              >
                Documentation
              </Button>
            </div>
          </div>
          <div
            id={`${ID_PREFIX}-tool-block-api`}
            data-pogr-id={`${ID_PREFIX}-tool-block-api`}
            className={styles.toolBlock}
          >
            <div
              id={`${ID_PREFIX}-tool-block-api-image-container`}
              data-pogr-id={`${ID_PREFIX}-tool-block-api-image-container`}
            >
              <img
                id={`${ID_PREFIX}-tool-block-image`}
                data-pogr-id={`${ID_PREFIX}-tool-block-image`}
                src="/static/icons/APIIcon.svg"
                alt="sdk"
              />
            </div>
            <div
              id={`${ID_PREFIX}-tool-block-api-text-container`}
              data-pogr-id={`${ID_PREFIX}-tool-block-api-text-container`}
            >
              <h3
                id={`${ID_PREFIX}-tool-block-text`}
                data-pogr-id={`${ID_PREFIX}-tool-block-api-text`}
              >
                API
              </h3>
            </div>
            <div
              id={`${ID_PREFIX}-tool-block-api-description-container`}
              data-pogr-id={`${ID_PREFIX}-tool-block-api-description-container`}
            >
              <span
                id={`${ID_PREFIX}-tool-block-description`}
                data-pogr-id={`${ID_PREFIX}-tool-block-description`}
                className={styles.description}
              >
                Every. Little. Detail. Connect directly to our JSON REST API
                in-game and out. Great for web apps, bots, hooks.
              </span>
            </div>
            <div
              id={`${ID_PREFIX}-tool-block-api-what-we-provide-button-container`}
              data-pogr-id={`${ID_PREFIX}-tool-block-api-what-we-provide-button-container`}
            >
              <Button
                id={`${ID_PREFIX}-tool-block-api-what-we-provide-button`}
                data-pogr-id={`${ID_PREFIX}-tool-block-api-what-we-provide-button`}
                variant="contained"
                color="secondary"
                endIcon={<FontAwesomeIcon icon={faAngleRight} />}
              >
                Coming soon
              </Button>
            </div>
            <div
              id={`${ID_PREFIX}-tool-block-api-documentation-button-container`}
              data-pogr-id={`${ID_PREFIX}-tool-block-api-documentation-button-container`}
            >
              <Button
                id={`${ID_PREFIX}-tool-block-documentation-button`}
                data-pogr-id={`${ID_PREFIX}-tool-block-documentation-button`}
                variant="text"
                startIcon={<FontAwesomeIcon width={12} icon={faFile} />}
                disabled
              >
                Documentation
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
}

export default OurToolboxSection;
