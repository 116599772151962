import React from "react";
import Page from "../../components/layouts/page/Page";
import styles from "./LegalPageStyles.module.scss";

type Props = {};

function PrivacyPage({}: Props) {
  return (
    <Page>
      <div className={styles.wrapper}>
        <h2>Privacy Policy</h2>
        <div className={styles.tableOfContent}> </div>
        <p>
          POGR (Play of the Game Rating) is a next-generation platform fueled by
          data and analytics that enables the ultimate player profiles. POGR
          enables you to track gaming statistics from your gaming and social
          media accounts onto one customizable platform, enabling you to manage
          multiple accounts at once.
        </p>

        <p>
          This Privacy Notice explains how POGR (referred to below as “POGR”,
          “Company”, “we”, “us”, or “our”) collects, uses, discloses, and
          otherwise processes personal data (as defined below) in connection
          with our websites including pogr.gg (“Sites”), our other online
          properties, our related content, platform, services, products, our
          software development kit (“SDK”), our application programming
          interface (“API”), and other functionalities offered on our through
          our services (collectively, the “Services”).
        </p>

        <h3>1. What is Personal Data?</h3>
        <p>
          When we use the term “personal data” in this Privacy Notice, we mean
          any data or information that identifies, relates to, describes, is
          reasonably capable of being associated with, or could reasonably be
          linked, directly or indirectly, with a particular individual or
          household.
        </p>

        <h3>2. Our Collection of Personal Data</h3>
        <p>
          We collect personal data in a variety of ways, including from you
          directly and from third parties. In addition, we collect certain
          information automatically through tracking technologies.
        </p>
        <p>
          In some cases, we link or combine personal data from more than one
          source. For example, we receive gameplay data that can be associated
          with POGR user accounts from developers that we have a relationship
          with.
        </p>
        <p>
          Our collection of personal data is described in more detail below.
        </p>
        <h4>Personal Data Collected From You Directly</h4>
        <p>
          We may collect the following categories of personal data submitted to
          us by individuals through the Services:
        </p>
        <ul>
          <li>
            <p>
              <b>Account Information</b>: including full name, email address,
              mailing address, country or region, social media handle(s), phone
              number, profile picture, username and password, affiliations with
              studios or e-sports teams, payment and purchase history
              information, interests (e.g., history, nature, sports), gender,
              age, and any other information you provide to us. We use this
              information to administer your account, provide you with the
              relevant Services-related information, communicate with you
              regarding your account and your use of the Services, and for
              customer support purposes.
            </p>
          </li>
          <li>
            <p>
              <b>Gaming Information</b>: including personal data related to game
              play including actions performed in a game/match, unique ID,
              username for played games, ranking information, device information
              such as device type and/or manufacturer and operating system.
            </p>
          </li>
          <li>
            <p>
              <b>Surveys, Contests, Sweepstakes or Promotions</b>: including
              information collected when you interact with us through a survey,
              contest, sweepstakes, or other promotion, we may collect personal
              data from you including your name, email address, mailing address,
              phone number, and any other information you provide to us (e.g.,
              your responses to our survey questions).
            </p>
          </li>
          <li>
            <p>
              <b>Payment Information</b>: including information collected in
              connection with signing up for one of our Services that requires
              payment (i.e., a POGR subscription). Please note that we use third
              party payment processors, including through Stripe, PayPal, and
              Xsolla to process credit card payments made to us. As such, we do
              not retain any personally identifiable financial information in
              connection with credit card payments, such as credit card numbers.
              Rather, all such information is provided directly by you to our
              third-party processor. The payment processor’s use of your
              personal data is governed by their privacy notice. Please see
              Stripe’s Privacy Policy{" "}
              <a href="https://stripe.com/privacy" target="_blank">
                here
              </a>
              , PayPal’s Privacy Statement{" "}
              <a
                href="https://www.paypal.com/us/legalhub/privacy-full"
                target="_blank"
              >
                here
              </a>
              , and Xsolla’s Privacy Policy
              <a
                href="https://xsolla.com/privacypolicy/?lang=en"
                target="_blank"
              >
                here
              </a>
              .
            </p>
          </li>
          <li>
            <p>
              <b>Inquiry and Communications Information</b>: including
              information provided in custom messages sent through our Services
              (e.g. via our ticketing system) or to one of our email addresses.
              This also includes contact information provided on our Services.
              We use this information to investigate and respond to your
              inquiries, and to communicate with you, to enhance the Services
              and to manage and grow our organization.
            </p>
          </li>
          <li>
            <p>
              <b>Newsletter, Marketing Emails, and Blog Information</b>:
              including email address and applicable interests and communication
              preferences. We use this information to manage our communications
              with you and send you information about products and services we
              think may be of interest to you. If you wish to stop receiving
              email messages from us, you can click the “unsubscribe link”
              provided at the bottom of the email communication. Note that you
              cannot unsubscribe from certain services-related email
              communications (e.g., account verification, confirmations of
              transactions, technical or legal notices).
            </p>
          </li>
          <li>
            <p>
              <b>Information Collected Through the Use of the Services</b>:
              including any files, documents, videos, images, data, or
              information you choose to upload or transmit through your
              communications with us or your use of the Sites (collectively,
              <b>“User Content”</b>). User Content and any information contained
              in the User Content, including personal data you may have
              included, is stored, and collected as part of the Services. We use
              the User Content to operate, improve, personalize, and optimize
              the Services, and to manage and deliver advertising.
            </p>
          </li>
          <li>
            <p>
              <b>Feedback Information</b>: We may also collect feedback and
              ratings you provide relating to our services or products. We use
              this information to communicate with you, to conduct market
              research, inform our marketing and advertising activities and
              improve and grow our business.
            </p>
          </li>
          <li>
            <p>
              <b>Other Data You Share</b>: including personal data collected
              through other points on our Services so that we may provide the
              features or functionality of the Services and for other purposes
              described in this Privacy Notice. We may also collect your
              personal data or data if you submit it to us in other contexts
              (e.g., if you provide us with a testimonial).
            </p>
          </li>
        </ul>
        <h4>Personal Data from Third Parties</h4>
        <p>
          We also obtain personal data from third parties, and combine it with
          personal data we collect either automatically or directly from an
          individual.
        </p>
        <p>
          We may receive the same categories of personal data as described above
          from the following third parties:
        </p>
        <ul>
          <li>
            <p>
              <b>POGR Entities</b>: We may receive personal data from other
              companies and brands owned or controlled by POGR, and other
              companies owned or under common ownership as POGR.
            </p>
          </li>
          <li>
            <p>
              <b>Game Developers</b>: We may receive gaming information from
              game developers such as memory, RAM, CPU, game events, and other
              related game data. We use this information to create unique IDs
              for gamers that may be associated with your POGR profile.
            </p>
          </li>
          <li>
            <p>
              <b>Your Employer / Company</b>: If you interact with our Services
              through your employer or company, we may receive your information
              from your employer or company, including another representative of
              your employer or company. We use this information to operate,
              maintain, and provide the features and functionality of the
              Services. For example, if your company or employer has engaged
              POGR to provide an enterprise version of certain Services, we may
              receive your information in connection with that engagement.
            </p>
          </li>
          <li>
            <p>
              <b>Others who Interact with our Services</b>: We may receive your
              information from other users or other individuals who interact
              with our Services. For example, we may receive information about
              you if another user tags you in a post or shares content that you
              have posted with others.
            </p>
          </li>
          <li>
            <p>
              <b>Social Media</b>: When you interact with our Services through
              social media networks, such as when someone “Likes” us on Facebook
              or follows us or shares our content on Google, Facebook, Twitter,
              Steam, or other social networks, we may receive information that
              you permit the social network to share with third parties. The
              data we receive depends on your privacy settings with the social
              network, and may include your profile information, profile
              picture, gender, username, user ID associated with your social
              media account, age range, language, country, and any other
              information you permit the social network to share with third
              parties. Individuals should always review and, if necessary,
              adjust their privacy settings on third-party websites and social
              media networks and services before sharing information and/or
              linking or connecting them to other services. We use this
              information to operate, maintain, and provide to you the features
              and functionality of the Services, as well as to communicate
              directly with you, such as to send you email messages about
              products and services that may be of interest to you.
            </p>
          </li>
          <li>
            <p>
              <b>Service Providers</b>: Our service providers sometimes collect
              personal data on our on our behalf. The information may include
              contact information, demographic information, payment information,
              information about your communications and related activities, and
              information about your subscription(s). We may use this
              information to administer and facilitate our Services and our
              marketing activities.
            </p>
          </li>
          <li>
            <p>
              <b>Business Partners</b>: We may receive your personal data from
              our business partners as well as other companies that offer their
              products and/or services in connection with our Services. For
              example, we receive Gaming Information from Overwolf such as
              personal data related to game play, including actions performed in
              a game/match, unique ID, username for played games, ranking
              information, device information such as device type and/or
              manufacturer and operating system. We may use this information to
              administer and facilitate our services, your orders, and our
              marketing activities.
            </p>
          </li>
          <li>
            <p>
              <b>Third-Party Authentication Services</b>: Some parts of our
              Services may allow you to use a third-party authentication service
              during log-in. These services will authenticate your identity and
              provide you the option to share certain authentication data with
              us. The data we receive is dependent on the authentication
              provider’s policies and your privacy settings with the
              authentication provider. We use this information to authenticate
              your account and operate, maintain, and provide to you the
              features and functionality of the Services. We may also send you
              Service-related messages (e.g., account verification, purchase
              confirmation, customer support, changes, or updates to features of
              the Services, technical and security notices).
            </p>
          </li>
          <li>
            <p>
              <b>Data Providers</b>: To market our Services, we may purchase or
              receive personal data, such as name and mailing addresses, from
              data providers and third parties. We use this information to send
              POGR mailings with information on new products and services
              offered by POGR or our clients. We may combine this information
              with other information we collect from or about you. In these
              cases, our Privacy Notice governs the handling of the combined
              information.
            </p>
          </li>
          <li>
            <p>
              <b>Other Sources</b>: We may also collect Personal Data about
              individuals that we do not otherwise have from, for example,
              publicly available sources, third-party data providers, brand
              partnerships, or through transactions such as mergers and
              acquisitions. We use this information to operate, maintain, and
              provide to you the features and functionality of the Services, as
              well as to communicate directly with you, such as to send you
              email messages about products and services that may be of interest
              to you.
            </p>
          </li>
        </ul>

        <h3>3. How We Use Personal Data</h3>
        <h4>We may use personal data we collect to: </h4>
        <ul>
          <li>
            <p>
              Fulfill or meet the reason the information was provided, such as
              to fulfill our contractual obligations, to deliver the Services
              you have requested (including through the use of automated tools
              leveraging artificial intelligence) and to process transactions;
            </p>
          </li>
          <li>
            <p>Manage our organization and its day-to-day operations; </p>
          </li>
          <li>
            <p>Verify your identity and entitlement to specific Services;</p>
          </li>
          <li>
            <p>
              Communicate with individuals, including via email or via our
              internal ticketing system.;
            </p>
          </li>
          <li>
            <p>
              Request individuals to complete surveys about our organization,
              organizations we partner with, and the Services;{" "}
            </p>
          </li>
          <li>
            <p>
              For marketing and advertising purposes, including to market to you
              or offer you through email information and updates on products or
              services we think that you may be interested in;
            </p>
          </li>
          <li>
            <p>
              Administer, improve and personalize our Services, including by
              recognizing an individual and remembering their information when
              they return to our Services;
            </p>
          </li>
          <li>
            <p>Process payment for our Services;</p>
          </li>
          <li>
            <p>Identify and analyze how individuals use our Services;</p>
          </li>
          <li>
            <p>
              Conduct research and analytics on our customer and user base and
              our Services;
            </p>
          </li>
          <li>
            <p>
              Test, enhance, update and monitor the Services, or diagnose or fix
              technology problems;
            </p>
          </li>
          <li>
            <p>
              Help maintain the safety, security and integrity of our property
              and Services, technology assets and business;
            </p>
          </li>
          <li>
            <p>
              To enforce our Terms of Use, to resolve disputes, to carry out our
              obligations and enforce our rights, and to protect our business
              interests and the interests and rights of third parties;
            </p>
          </li>
          <li>
            <p>
              Prevent, investigate, or provide notice of fraud or unlawful or
              criminal activity;
            </p>
          </li>
          <li>
            <p>
              Comply with contractual and legal obligations and requirements;;
            </p>
          </li>
          <li>
            <p>
              To fulfill any other purpose for which you provide personal data;
              and
            </p>
          </li>
          <li>
            <p>
              For any other lawful purpose, or other purpose that you consent
              to.
            </p>
          </li>
        </ul>
        <p>
          Where you choose to contact us, we may need additional information to
          fulfill the request or respond to inquiries. We may provide you with
          additional privacy-related information where the scope of the
          inquiry/request and/or personal data we require fall outside the scope
          of this Privacy Notice. In that case, the additional privacy notice
          will govern how we may process the information provided at that time;
        </p>

        <h3>
          4. How We Use Cookies and Other Tracking Technology to Collect
          Information
        </h3>
        <p>
          We, and our third-party partners, automatically collect information
          you provide to us and information about how you access and use the
          Services when you visit our services, read our emails, or otherwise
          engage with us. We typically collect this information through a
          variety of tracking technologies, including (i) cookies or small data
          files that are stored on an individual’s computer and (ii) other,
          related technologies, such as web beacons, pixels, embedded scripts,
          mobile SDKs, location-identifying technologies and logging
          technologies (collectively, “tracking technologies”) and we may use
          third-party partners or technologies to collect this information.
          Information we collect automatically about you may be combined with
          other personal information we collect directly from you or receive
          from other sources. We, and our third-party partners, use tracking
          technologies to automatically collect usage and device information,
          such as:
        </p>
        <ul>
          <li>
            <p>
              <b>
                Information the computer, tablet, smartphone, or other device
                you use
              </b>
              , such as your IP address, browser type, Internet service
              provider, device type/model/manufacturer, operating system, date
              and time stamp, and a unique ID that allows us to uniquely
              identify your browser, mobile device, or your account (including,
              for example, a persistent device identifier or an Ad ID), and
              other such information. We may also work with third-party partners
              to employ technologies, including the application of statistical
              modeling tools, which permit us to recognize and contact you
              across multiple devices.
            </p>
          </li>
          <li>
            <p>
              <b>Information about the way you access and use our Services</b>,
              for example, the website from which you came and the website to
              which you are going when you leave our Services, how frequently
              you access the Services, whether you open emails or click the
              links contained in emails, whether you access the Services from
              multiple devices, and other browsing behavior and actions you take
              on the Services.
            </p>
          </li>
          <li>
            <p>
              <b>Information about how you use the Services</b>, such as the
              pages you visit, the links you click, the ads you view and click
              on, videos you watch, and other similar actions. We may also use
              third-party tools to collect information you provide to us or
              information about how you use the Services and may record your
              mouse movements, scrolling, clicks and keystroke activity on the
              Services and other browsing, search or purchasing behavior. These
              tools may also record information you enter when you interact with
              our Services or engage in chat features through our Services.
            </p>
          </li>
          <li>
            <p>
              <b>Information about your location</b>, such as general geographic
              location that we or our third-party providers may derive from your
              IP address.
            </p>
          </li>
          <li>
            <p>
              <b>Analytics information</b>, We may collect analytics data or use
              third-party analytics tools such as Google Analytics to help us
              measure traffic and usage trends for the Services and to
              understand more about the demographics of our users. You can learn
              more about Google’s practices at{" "}
              <a
                href="http://www.google.com/policies/privacy/partners"
                target="_blank"
              >
                http://www.google.com/policies/privacy/partners
              </a>{" "}
              and view its opt-out options at{" "}
              <a
                href="https://tools.google.com/dlpage/gaoptout"
                target="_blank"
              >
                https://tools.google.com/dlpage/gaoptout
              </a>{" "}
              .
            </p>
          </li>
        </ul>
        <p>
          All of the information collected automatically through these tools
          allows us to improve your customer experience. For example, we may use
          this information to enhance and personalize your user experience, to
          monitor and improve our Services, and to improve the effectiveness of
          our Services, offers, communications and customer service. We may also
          use this information the data collected through tracking technologies
          to: (a) remember information so that you will not have to re-enter it
          during your visit or the next time you visit the Services; (b) provide
          custom, personalized content and information, including targeted
          content; (c) identify you across multiple devices; (d) provide and
          monitor the effectiveness of our Services; (e) monitor aggregate
          metrics such as total number of visitors, traffic, usage, and
          demographic patterns on our Services; (f) diagnose or fix technology
          problems; and (g) otherwise to plan for and enhance our Services.{" "}
        </p>
        <p>
          If you would prefer not to accept cookies, most browsers will allow
          you to: (i) change your browser settings to notify you when you
          receive a cookie, which lets you choose whether or not to accept it;
          (ii) disable existing cookies; or (iii) set your browser to
          automatically reject cookies; however, doing so may negatively impact
          your experience using the Services, as some features may not work
          properly. You may also set your email options to prevent the automatic
          downloading of images that may contain technologies that would allow
          us to know whether you have accessed our email and performed certain
          functions with it.
        </p>

        <h3>5. OUR DISCLOSURE OF PERSONAL DATA</h3>
        <p>
          We may share, transmit, disclose, grant access to, make available, and
          provide personal data with and to third parties, as follows:
        </p>
        <ul>
          <li>
            <p>
              <b>Profile Viewers</b>: Profile information that you choose to
              make public, including vanity URL, display name, avatar, EXP and
              level will be posted publicly and can be viewed by users of our
              Services. You may adjust what information is publicly available in
              your Account Settings.
            </p>
          </li>
          <li>
            <p>
              <b>Discovery Partners</b>: POGR partners with third parties such
              as game developers and e-sports leagues to help them discover
              which POGR users are a good fit for opportunities such as early
              access to alpha/beta/testing trials, brand ambassador roles,
              nomination for awards or other recognition, Professional or
              Semi-Professional Scouting, Tournament or League outreach and
              invites, QA testing, and for recruitment purposes on a high
              school, college, professional level. POGR may be paid by third
              parties in connection with these partnerships. POGR users can opt
              in to their personal data being shared for these purposes.
            </p>
          </li>
          <li>
            <p>
              <b>Customer Services and Communication Providers</b>: We share
              personal data with third parties who assist us in providing our
              customer services and facilitating our communications with
              individuals that submit inquiries.
            </p>
          </li>
          <li>
            <p>
              <b>Other Service Providers</b>: In addition to the third parties
              identified above, we engage other third-party service providers
              that perform business or operational services for us or on our
              behalf, such as website hosting, infrastructure provisioning, IT
              services, analytics services, employment application-related
              services, payment processing services, and administrative
              services.
            </p>
          </li>
          <li>
            <p>
              <b>Business Partners</b>: From time to time, we may share personal
              data with our business partners, or we may allow our business
              partners to collect your personal data. Our business partners will
              use your information for their own business and commercial
              purposes, including to send you any information about their
              products or services that we believe will be of interest to you.
            </p>
          </li>
          <li>
            <p>
              <b>POGR Entities</b>: We may share personal data with other
              companies owned or controlled by POGR, and other companies owned
              by or under common ownership as POGR, which also includes our
              subsidiaries (i.e., any organization we own or control) or our
              ultimate holding company (i.e., any organization that owns or
              controls us) and any subsidiaries it owns, particularly when we
              collaborate in providing the Services.
            </p>
          </li>
          <li>
            <p>
              <b>Your Employer / Company</b>: If you maintain a Developer
              Account that is associated with an organization or associated with
              a game directly, and you interact with our Services through your
              employer or company, we may disclose your information to your
              employer or company, including another representative of your
              employer or company.
            </p>
          </li>
          <li>
            <p>
              <b>Business Transaction or Reorganization</b>: We may take part in
              or be involved with a corporate business transaction, such as a
              merger, acquisition, joint venture, or financing or sale of
              company assets. We may disclose personal data to a third-party
              during negotiation of, in connection with or as an asset in such a
              corporate business transaction. Personal data may also be
              disclosed in the event of insolvency, bankruptcy, or receivership.
            </p>
          </li>
          <li>
            <p>
              <b>Legal Obligations and Rights</b>:
              <p>
                We may disclose personal data to third parties, such as law
                enforcement:
              </p>
              <ul>
                <li>
                  <p>
                    in connection with the establishment, exercise, or defense
                    of legal claims;
                  </p>
                </li>
                <li>
                  <p>
                    to comply with laws or to respond to lawful requests and
                    legal process;
                  </p>
                </li>
                <li>
                  <p>
                    to protect our rights and property and the rights and
                    property of others, including to enforce our agreements and
                    policies;
                  </p>
                </li>
                <li>
                  <p>to detect, suppress, or prevent fraud;</p>
                </li>
                <li>
                  <p>to protect the health and safety of us and others; or</p>
                </li>
                <li>
                  <p>as otherwise required by applicable law.</p>
                </li>
              </ul>
            </p>
          </li>
          <li>
            <p>
              <b>With your Consent</b>: We may disclose personal data about an
              individual to certain other third parties or publicly with their
              consent or direction. For example, with an individual’s consent or
              direction we may post their testimonial on our Sites or
              Service-related publications.
            </p>
          </li>
        </ul>

        <h3>6. Control Over Your Information</h3>
        <p>You may control your information in the following ways:</p>
        <ul>
          <li>
            <p>
              <b>Access to Your Device Information</b>: You may control the
              Services’ access to your device information through your
              “Settings” app on your device. For instance, you can withdraw
              permission for the Services to access your network devices and
              geolocation and to integrate with your other applications.
            </p>
          </li>
          <li>
            <p>
              <b>Public Display of Profile</b>: You may control the settings
              allowing your profile to be discoverable to other players. This
              makes your profile private and disallows other users from being
              able to view your information.
            </p>
          </li>
          <li>
            <p>
              <b>Account Settings</b>: You may use your account settings to
              request information about your account, change basic information
              in your account, change widgets that display information on your
              account associated with specific games, applications or services,
              or view just general information. You also have the ability to
              delete your account.
            </p>
          </li>
          <li>
            <p>
              <b>Email Communications Preferences</b>: You can stop receiving
              promotional email communications from us by clicking on the
              “unsubscribe” link provided in such communications. You may not
              opt-out of Service-related communications (e.g., account
              verification, transactional communications, changes/updates to
              features of the Services, technical and security notices).
            </p>
          </li>
          <li>
            <p>
              <b>Push Notifications</b>: You can stop receiving push
              notifications from us by changing your preferences in the iOS or
              Android notifications settings menu.
            </p>
          </li>
          <li>
            <p>
              <b>SMS Text Messaging</b>: You may opt-out of receiving
              promotional SMS text messages at any time. Please note that
              unsubscribing from promotional text messages will not prevent you
              from SMS texts from POGR directly relating to your use of the
              Services, such as for account login (e.g., we may send you a
              security verification code). To unsubscribe from promotional SMS
              texts, you can reply STOP to any one of our promotional text
              messages. For more information, see our Terms of Use.
            </p>
          </li>
          <li>
            <p>
              <b>Modifying or Deleting Your Information</b>: If you have any
              questions about reviewing, modifying, or deleting your
              information, you can contact us directly at admin@pogr.io. We may
              not be able to modify or delete your information in all
              circumstances.
            </p>
          </li>
        </ul>

        <h3>7. Children’s Personal Data</h3>
        <p>
          Our Services are not intended to be used by children under the age of
          13. We do not intend to collect or solicit personal data directly from
          children under the age of 13 or knowingly collect personal data
          directly from children in that age group. Children under the age of 13
          should not use our Services or otherwise provide us with their
          personal data. If a child under the age of 13 has provided personal
          data to us, we encourage the child’s parent or guardian to contact us
          to request that we remove the personal data from our systems. If we
          learn that any personal data we collect has been provided to us
          directly by a child under the age of 13, we will promptly delete that
          personal data.
        </p>

        <h3>8. Links to Third-Party Websites or Services</h3>
        <p>
          Our Services may include links to third-party websites, plug-ins, and
          applications that we do not own or operate. We are not responsible for
          the practices employed by any websites or services linked to or from
          the Services, including the information or content contained within
          them. Our Privacy Notice does not apply to your browsing or other
          interactions on any other website or service. Activities on third
          party websites or services are subject to that applicable third
          party’s rules and policies, not ours. If you are using a third-party
          website or service, you do so at your own risk. We encourage you to
          review the privacy policies of any site or service before providing
          any personal data.
        </p>

        <h3>9. Updates to This Privacy Notice</h3>
        <p>
          We may update this Privacy Notice from time to time. When we make
          changes to this Privacy Notice, we will change the date at the
          beginning of this Privacy Notice. If we make material changes to this
          Privacy Notice, we will notify individuals by email to their
          registered email address, by prominent posting on our Services, or
          through other appropriate communication channels. All changes shall be
          effective from the date of publication unless otherwise provided.
        </p>

        <h3>10. Contact Us</h3>
        <p>
          If you have any questions or requests in connection with this Privacy
          Notice or other privacy-related matters, please send an email to{" "}
          <a href="mailto:admin@pogr.io">admin@pogr.io</a>.
        </p>

        <h3>11. ADDITIONAL U.S. STATE PRIVACY DISCLOSURES</h3>
        <p>
          These Additional U.S. State Privacy Disclosures (
          <b>“U.S. Disclosures”</b>) supplement the information contained in our
          Privacy Notice by providing additional information about our personal
          data processing practices relating to individual residents of states
          for which additional disclosures may be required under applicable
          state privacy laws. For a detailed description of how we collect, use,
          disclose, and otherwise process personal data in connection with our
          Services, please visit our Privacy Notice. Unless otherwise expressly
          stated, all terms defined in our Privacy Notice retain the same
          meaning in these U.S. Disclosures.
        </p>
        <p>
          For the purposes of these U.S. Disclosures, personal data does not
          include publicly available information or deidentified, aggregated or
          anonymized information that is maintained in a form that is not
          capable of being associated with or linked to you.
        </p>

        <h4>Your Privacy Choices</h4>
        <p>
          Depending on your state of residency, you may be able to exercise the
          following rights in relation to the personal data about you that we
          have collected (subject to certain limitations at law):
        </p>
        <ul>
          <li>
            <p>
              <b>The Right to Know</b>: The right to confirm whether we are
              processing personal data about you and, under California law only,
              to obtain certain personalized details about the personal data we
              have collected about you in the last 12 months, including:
              <ul>
                <li>
                  <p>The categories of personal data collected;</p>
                </li>
                <li>
                  <p>The categories of sources of the personal data;</p>
                </li>
                <li>
                  <p>
                    The purposes for which the personal data were collected;
                  </p>
                </li>
                <li>
                  <p>
                    The categories of personal data disclosed to third parties
                    (if any), and the categories of recipients to whom the
                    personal data were disclosed;
                  </p>
                </li>
                <li>
                  <p>
                    The categories of personal data shared for cross-context
                    behavioral advertising purposes (if any), and the categories
                    of recipients to whom the personal data were disclosed for
                    those purposes; and
                  </p>
                </li>
                <li>
                  <p>
                    The categories of personal data sold (if any), and the
                    categories of third parties to whom the personal data were
                    sold.
                  </p>
                </li>
              </ul>
            </p>
          </li>
          <li>
            <p>
              <b>The Right to Access and Portability</b>: The right to obtain
              access to the personal data we have collected about you and, where
              required by law, the right to obtain a copy of the personal data
              in a portable and, to the extent technically feasible, readily
              usable format that allows you to transmit the data to another
              entity without hindrance.
            </p>
          </li>
          <li>
            <p>
              <b>The Right to Request Deletion</b>: You have the right to
              request the deletion of personal data that we have collected from
              you, subject to certain exceptions.
            </p>
          </li>
          <li>
            <p>
              <b>The Right to Correction</b>: You have the right to request that
              any inaccuracies in your personal data be corrected, taking into
              account the nature of the personal data and the purposes of the
              processing of your personal data.
            </p>
          </li>
          <li>
            <p>
              <b>Right To Withdraw Consent</b>: If you have provided consent for
              the processing of your personal data, you may have the right to
              withdraw your consent. If you withdraw your consent, this will not
              affect the lawfulness of our use of your personal data before your
              withdrawal.
            </p>
          </li>
          <li>
            <p>
              <b>Right To Opt-Out</b>: You may have the right to opt-out of
              certain processing activities. For example, you may have the right
              to opt-out of the use of your personal data for targeted
              advertising purposes, or to “sell” or “share” your personal data
              with third parties in certain contexts.
            </p>
          </li>
        </ul>
        <p>
          Depending on your state of residency, you may also have the right to
          not receive retaliatory or discriminatory treatment in connection with
          a request to exercise the above rights. However, the exercise of the
          rights described above may result in a different price, rate or
          quality level of product or service where that difference is
          reasonably related to the impact the right has on our relationship or
          is otherwise permitted by law.
        </p>

        <h4>How to Exercise Your Privacy Rights</h4>
        <p>
          To submit a request to exercise one of the privacy rights identified
          above, please submit a request by:
        </p>
        <ul>
          <li>
            <p>
              Emailing admin@pogr.io with the subject line “Data Subject Rights
              Request,”
            </p>
          </li>
          <li>
            <p>Contacting via our internal discord</p>
          </li>
          <li>
            <p>Filling out our Individual Rights Request Form</p>
          </li>
        </ul>
        <p>
          We may need to verify your identity before processing your request,
          which may require us to request additional personal data from you or
          require you to log into your account, if you have one. We will only
          use personal data provided in connection with a Privacy Rights request
          to review and comply with the request.
        </p>
        <p>
          In certain circumstances, we may decline a request to exercise the
          rights described above, particularly where we are unable to verify
          your identity or locate your information in our systems. If we are
          unable to comply with all or a portion of your request, we will
          explain the reasons for declining to comply with the request.
        </p>

        <h4>To Exercise Your Opt-Out Rights</h4>
        <p>
          Unless you have exercised your Right to Opt-Out, and as described in
          the <b>Our Disclosure of Personal Data</b> section of the Privacy
          Notice, we may disclose or “sell” your personal data to third parties
          for monetary or other valuable consideration in relation to online
          tracking technologies, or “share” your personal data to third parties
          for cross-context behavioral advertising purposes (targeted
          advertising). The third parties to whom we sell or share personal data
          may use such information for their own purposes in accordance with
          their own privacy policies.
        </p>
        <p>
          The third parties to whom we sell or share personal data may use such
          information for their own purposes, in accordance with their own
          privacy statements, which may include reselling or sharing this
          information to additional third parties.
        </p>
        <p>
          You do not need to create an account with us to exercise your Right
          to Opt-Out. However, we may ask you to provide additional personal
          data so that we can properly identify you in our dataset and to track
          compliance with your opt out request. We will only use personal data
          provided in an opt out request to review and comply with the request.
          If you chose not to provide this information, we may only be able to
          process your request to the extent we are able to identify you in our
          data systems.
        </p>
        <ul>
          <li>
            <p>
              For Cookie-Based Sales & Targeted Advertising Opt-Outs: To
              exercise your right to opt-out as it relates to the use of cookies
              and other tracking technologies for analytics and targeted ads,
              click here.
            </p>
          </li>
          <li>
            <p>
              For Non-Cookie-Based Opt-Outs: You may submit a request by
              emailing admin@pogr.io or by filling out our Individual Rights
              Request Form.
            </p>
          </li>
        </ul>
        <h4>Authorized Agents</h4>
        <p>
          In certain circumstances, you are permitted to use an authorized agent
          to submit requests on your behalf through the designated methods set
          forth above where we can verify the authorized agent’s authority to
          act on your behalf. In order to verify the authorized agent’s
          authority, we generally require evidence of either (i) a valid power
          of attorney (where required by applicable law) or (ii) a signed letter
          containing your name and contact information, the name and contact
          information of the authorized agent, and a statement of authorization
          for the request. Depending on the evidence provided and your state of
          residency, we may still need to separately reach out to you to confirm
          the authorized agent has permission to act on your behalf and to
          verify your identity in connection with the request.
        </p>
        <h4>Appealing Privacy Rights Decisions</h4>
        <p>
          Depending on your state of residency, you may be able to appeal a
          decision we have made in connection with your privacy rights request.
          All appeal requests should be submitted using the Individual Rights
          Request Form or emailing admin@pogr.io.
        </p>
        <h4>California-Specific Disclosures</h4>
        <p>
          The following disclosures only apply to residents of the State of
          California.
        </p>
        <ul>
          <li>
            <p>
              <b>Personal Data Collection</b>: In the last 12 months, we may
              have collected the following categories of personal data:
              identifiers, customer records, commercial information,
              internet/network information, geolocation data,
              professional/employment information and inferences generated from
              your use of our Services. For more information about our
              collection of personal data, the sources of personal data, and how
              we use this information, please see the{" "}
              <b>Our Collection of Personal Data</b> and{" "}
              <b>How We Use Personal Data</b> sections of our{" "}
              <b>Privacy Notice</b>.
            </p>
          </li>
          <li>
            <p>
              <b>Disclosure of Personal Data</b>: In the last 12 months, we may
              have disclosed all of the categories of information we collect
              with third parties for a business purpose, as described in the{" "}
              <b>Our Disclosure of Personal Data</b> section of the Privacy
              Notice. The categories of third parties to whom we sell or
              disclose your personal data for a business purpose include:
              <ul>
                <li>
                  <p>
                    Business partners, service providers and advisors that
                    perform services for us, on our behalf, which may include
                    providing marketing and advertising services, providing
                    mailing or email services, tax and accounting services, data
                    enhancement services, fraud prevention, web hosting, or
                    similar services.
                  </p>
                </li>
                <li>
                  <p>
                    Select marketing and strategic partners who use this
                    information to market to you, to perform analytics and
                    conduct research, or for other purposes;
                  </p>
                </li>
                <li>
                  <p>
                    Ad networks and advertising partners, including social
                    networks, to deliver advertising and personalized content to
                    you on our Services, on other sites and services you may
                    use, and across other devices you may use, as well as
                    provide advertising-related services such as reporting,
                    attribution, analytics, and market research;
                  </p>
                </li>
                <li>
                  <p>
                    Social networks for advertising or personalization purposes,
                    or to engage with you.
                  </p>
                </li>
                <li>
                  <p>
                    Analytics providers to better understand the demographics of
                    our users and visitors, and to personalize, enhance and
                    improve our Services;
                  </p>
                </li>
                <li>
                  <p>
                    We may also disclose personal data to other third parties at
                    your direction or upon your request, or to comply with legal
                    process or contractual obligations, as described in our
                    Privacy Notice.
                  </p>
                </li>
              </ul>
            </p>
          </li>
          <li>
            <p>
              <b>Sales of Personal Data and Sharing for Targeted Advertising</b>
              : As further described in the Our Disclosures of Personal Data
              section of the Privacy Notice, we may “sell” your personal data
              (as defined by the applicable privacy laws) to or share your data
              with third parties, subject to your right to opt out (see Your
              Privacy Choices above).
              <p>
                We have sold or shared the following categories of personal data
                for the purposes described in our Privacy Notice, subject to
                your settings and preferences and your Right to Opt-Out:
                identifiers, commercial information, internet/network
                information, geolocation information, inferences generated from
                your use of our Services.{" "}
              </p>
              <p>
                The categories of third parties to whom we may sell or share the
                personal data include:
              </p>
              <ul>
                <li>
                  <p>Business and Marketing Partners</p>
                </li>
                <li>
                  <p>Online Advertising Networks and Analytics Providers</p>
                </li>
                <li>
                  <p>Social Networks</p>
                </li>
              </ul>
              <p>
                We may also disclose personal data for the purposes described in
                the Our Disclosure of Personal Data section of our Privacy
                Notice. In addition, please see Third-Party Data Collection and
                Online Advertising section of the Privacy Notice to learn more
                about how third-party advertising networks, social media
                companies and other third- party businesses collect and disclose
                your personal data directly from your browser or device through
                cookies or tracking technologies when you visit or interact with
                our Services or otherwise engage with us.
              </p>
            </p>
          </li>
          <li>
            <p>
              <b>Sensitive Information</b>: We do not sell sensitive
              information, and we do not process or otherwise share sensitive
              information for the purpose of targeted advertising. However,
              depending on your state of residency and subject to certain legal
              limitations and exceptions, you may be able to limit, or withdraw
              your consent for, our processing of sensitive information (as
              described in the Your Privacy Choices section above).
            </p>
          </li>
          <li>
            <p>
              <b>Minors</b>: We do not currently sell the personal data of
              individual we know are under 16 years of age. If we wish to do so
              in the future, we will first seek affirmative authorization to the
              extent required by applicable law.
            </p>
          </li>
          <li>
            <p>
              <b>Minors</b>: We do not currently sell the personal data of
              individual we know are under 16 years of age. If we wish to do so
              in the future, we will first seek affirmative authorization to the
              extent required by applicable law.
            </p>
            <p>
              If you wish to submit a privacy request on behalf of your minor
              child in accordance with applicable jurisdictional laws, you must
              provide sufficient information to allow us to reasonably verify
              your child is the person about whom we collected personal data and
              you are authorized to submit the request on your child’s behalf
              (i.e., you are the child’s legal guardian or authorized
              representative).
            </p>
          </li>
          <li>
            <p>
              <b>“Shine the Light”</b>: The California “Shine the Light” law
              gives residents of California the right under certain
              circumstances to request information from us regarding the manner
              in which we share certain categories of personal data (as defined
              in the Shine the Light law) with third parties for their direct
              marketing purposes. To opt out of this type of sharing, please
              email us at admin@pogr.io.
            </p>
          </li>
          <li>
            <p>
              <b>Notice of Financial Incentives</b>: We may offer various
              programs, promotions and other financial incentives that may
              result in differences in our prices or services offered to
              consumers. For example, we may offer discounts, or other benefits
              in connection with signing up to use our Services. To obtain
              access to these offerings, we may collect and retain personal
              data, such as name, contact information, professional information,
              account information.
            </p>
            <p>
              We have determined that the value of these programs and other
              incentives are reasonably related to the value of the personal
              data we receive and otherwise process in connection with these
              programs and offerings, based on our reasonable but sole
              determination. We estimate the value of the personal data we
              receive and otherwise process in connection with these programs
              and offerings by considering the expense we incur in collecting
              and processing the personal data, as well as the expenses related
              to facilitating the program or offering.
            </p>
            <p>
              The material aspects of any financial incentive will be explained
              and described in its program terms or in the details of the
              incentive offer. Participating in any financial incentive program
              is entirely optional and participants may withdraw from the
              program at any time. To opt-out of the program and forgo any
              ongoing incentives, please follow the instructions in the
              program’s terms and conditions or contact us using the contact
              information below. Participating individuals may also opt out of
              receiving marketing communications by contact us at admin@pogr.io
              or follow the unsubscribe directions located in the footer of our
              electronic marketing messages.
            </p>
          </li>
        </ul>
        <h4>Nevada-Specific Disclosures</h4>
        <p>
          If you are a resident of the State of Nevada, Chapter 603A of the
          Nevada Revised Statutes permits a Nevada resident to opt out of future
          sales of certain covered information that a website operator has
          collected or will collect about the resident. Although we do not
          currently sell covered information, please contact us at admin@pogr.io
          with the subject line “Nevada Opt Out Request” to submit such a
          request.
        </p>
      </div>
    </Page>
  );
}

export default PrivacyPage;
