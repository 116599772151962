import { Button } from "@mui/material";
import Section from "../../components/layouts/section/Section";
import styles from "./AboutPogr.module.scss";

type Props = {};

const ID_PREFIX = "about-pogr-section";

const AboutPogr = ({}: Props) => {
  return (
    <Section
      id={`${ID_PREFIX}-main`}
      pogrId={`${ID_PREFIX}-main`}
      style={{ backgroundColor: "#292936" }}
    >
      <div
        id={`${ID_PREFIX}-container`}
        data-pogr-id={`${ID_PREFIX}-container`}
        className={styles.container}
      >
        <div
          id={`${ID_PREFIX}-brand-image-container`}
          data-pogr-id={`${ID_PREFIX}-brand-image-container`}
          className={styles.imageContainer}
        >
          <img
            id={`${ID_PREFIX}-brand-image`}
            data-pogr-id={`${ID_PREFIX}-brand-image`}
            src={"/static/brand/brand-logo.svg"}
            alt="brand"
          />
        </div>
        <div
          id={`${ID_PREFIX}-content-container`}
          data-pogr-id={`${ID_PREFIX}-content-container`}
          className={styles.contentContainer}
        >
          <div
            id={`${ID_PREFIX}-about-container`}
            data-pogr-id={`${ID_PREFIX}-about-container`}
            className={styles.aboutContainer}
          >
            <div
              id={`${ID_PREFIX}-about-title`}
              data-pogr-id={`${ID_PREFIX}-about-title`}
              className={styles.titleContainer}
            >
              About POGR
            </div>
            <div
              id={`${ID_PREFIX}-about-description`}
              data-pogr-id={`${ID_PREFIX}-about-description`}
              className={styles.descriptionContainer}
            >
              <p>
                POGR (Play Of the Game Rating) was formed in 2021 among three
                passionate gamers with a love for competition. It’s three heads:
                Connor Ellison (CEO), Randolph Aarseth (CTO) and Juno Kim (CPO)
                took there combined experience and turned an idea for the
                ultimate player profile and turn it into a 2.25 Million company
                within the span of 2 years in the middle of a recession.
                Featuring a high powered work culture and ethic. POGR seeks to
                become the definitive big data company within the gaming space
                by bridging the gaps and become the standard for game developers
                and players.
              </p>
              <p>
                The system allows you to track all your gaming statistics: yes,
                all of them, including your social media. The profiles aggregate
                all the analytics from your gaming and social media accounts
                onto one customizable platform, enabling you to manage multiple
                accounts at once with tons of analytical feedback to boot.
              </p>
            </div>
          </div>
          <div
            id={`${ID_PREFIX}-pogr-in-media-container`}
            data-pogr-id={`${ID_PREFIX}-pogr-in-media-container`}
            className={styles.mediaContainer}
          >
            <div
              id={`${ID_PREFIX}-pogr-in-media-title`}
              data-pogr-id={`${ID_PREFIX}-pogr-in-media-title`}
              className={styles.mediaTitle}
            >
              POGR in the media
            </div>
            <div
              id={`${ID_PREFIX}-pogr-in-media-button-container`}
              data-pogr-id={`${ID_PREFIX}-pogr-in-media-button-container`}
              className={styles.buttonContainer}
            >
              <Button
                id={`${ID_PREFIX}-ign-article-button`}
                data-pogr-id={`${ID_PREFIX}-ign-article-button`}
                className={styles.buttonWithIcon}
                href={
                  "https://nordic.ign.com/advertorial/64431/promoted/powered-by-pogr-transforming-usernames-into-identities-with-the-ultimate-player-profile"
                }
                target="_blank"
                variant="contained"
                color="secondary"
                size="large"
                startIcon={
                  <div className={styles.iconContainer}>
                    <img src={"/static/article.svg"} alt="Article Icon" />
                  </div>
                }
              >
                IGN Article
              </Button>
              <Button
                id={`${ID_PREFIX}-nyweekly-button`}
                data-pogr-id={`${ID_PREFIX}-nyweekly-button`}
                className={styles.buttonWithIcon}
                href={
                  "https://nyweekly.com/entertainment/pogr-perfects-the-art-of-analytics-to-c/"
                }
                target="_blank"
                variant="contained"
                color="secondary"
                size="large"
                startIcon={
                  <div className={styles.iconContainer}>
                    <img src={"/static/article.svg"} alt="Article Icon" />
                  </div>
                }
              >
                NYWeekly
              </Button>
              <Button
                id={`${ID_PREFIX}-linkedin-button`}
                data-pogr-id={`${ID_PREFIX}-linkedin-button`}
                className={styles.buttonWithIcon}
                href={"https://www.linkedin.com/company/pogr-io/"}
                target="_blank"
                variant="contained"
                color="secondary"
                size="large"
                startIcon={
                  <div className={styles.iconContainer}>
                    <img src={"/static/linkedin.svg"} alt="LinkedIn Icon" />
                  </div>
                }
              >
                LinkedIn
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default AboutPogr;
