import Axios from "axios";

type POSTMailistArgs = {
  email: string;
  developer: boolean;
  source: string;
  referrer_code?: string | null;
};

export const PostMailistLive = async ({
  email,
  developer,
  source,
  referrer_code,
}: POSTMailistArgs) => {
  const data = await Axios({
    method: "POST",
    url: "/mailinglist",
    data: {
      email,
      developer,
      source,
      referrer_code,
    },
    baseURL: process.env.REACT_APP_MARKETING_API,
  });

  return data.data;
};
