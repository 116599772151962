import React, { useState } from "react";
import IntersectionObserverWrapper from "../../components/intersection-observer-wrapper/IntersectionObserverWrapper";
import Section from "../../components/layouts/section/Section";
import styles from "./DevWidgetSection.module.scss";

type Props = {};

const ID_PREFIX = "dev-widget-section";

function DevWidgetSection({}: Props) {
  const [isVisible, setIsVisible] = useState(false);

  const handleChange = (visible: boolean) => {
    if (visible) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  return (
    <Section id={`${ID_PREFIX}-main`} pogrId={`${ID_PREFIX}-main`}>
      <div
        id={`${ID_PREFIX}-container`}
        data-pogr-id={`${ID_PREFIX}-container`}
        className={styles.container}
      >
        <div
          id={`${ID_PREFIX}-text-section`}
          data-pogr-id={`${ID_PREFIX}-text-section`}
          className={`${styles.textSection} ${
            isVisible ? styles.visible : styles.notvisible
          }`}
        >
          <IntersectionObserverWrapper
            id={`${ID_PREFIX}-intersection-observer`}
            pogrId={`${ID_PREFIX}-intersection-observer`}
            onChange={handleChange}
          >
            <h2 id={`${ID_PREFIX}-title`} data-pogr-id={`${ID_PREFIX}-title`}>
              <strong>Simplify & Scale!</strong>
            </h2>
          </IntersectionObserverWrapper>
          <br />
          <p
            id={`${ID_PREFIX}-description`}
            data-pogr-id={`${ID_PREFIX}-description`}
          >
            Fully loaded customizable dashboard built with developers in mind.
            Track user growth, identify trends and balance issues. We identify
            points of failure for your game and help you correct via data driven
            feedback. We are painfully aware of a developers limited time and
            resources, so we make it as easy and simple as possible to integrate
            and begin your data visualization so you can focus on what’s
            important.
          </p>
        </div>
        <div
          id={`${ID_PREFIX}-widgets-container`}
          data-pogr-id={`${ID_PREFIX}-widgets-container`}
          className={styles.widgetSection}
        >
          <img
            id={`${ID_PREFIX}-dev-widget-image`}
            data-pogr-id={`${ID_PREFIX}-dev-widget-image`}
            width={"100%"}
            src={"/static/dev-widget-screenshot.webp"}
            alt=""
          />
          {/* <Widget />
            <Widget />
            <Widget />
            <Widget />
            <Widget />
            <Widget /> */}
        </div>
      </div>
    </Section>
  );
}

export default DevWidgetSection;
