import React, { useState } from "react";
import Section from "../../components/layouts/section/Section";
import styles from "./PricingFeaturesSection.module.scss";
import pricing_feature_data from "../../demo-db/pricing/pricing-features.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCode } from "@fortawesome/free-solid-svg-icons";
import IntersectionObserverWrapper from "../../components/intersection-observer-wrapper/IntersectionObserverWrapper";
import { animated, config, useSpring, useTrail } from "react-spring";

type Props = {};

const ID_PREFIX = "pricing-features-section";

const FeatureItem = ({
  id = "",
  pogrId = "",
  title = "",
  features = [],
  icon = "",
  style,
}: {
  id?: string;
  pogrId?: string;
  title: string;
  features: Array<string>;
  icon: string;
  style: any;
}) => {
  return (
    <animated.div style={style}>
      <div
        id={`${id}-container`}
        data-pogr-id={`${pogrId || id}-container`}
        className={styles.itemWrapper}
      >
        <div
          id={`${id}-icon-container`}
          data-pogr-id={`${pogrId || id}-icon-container`}
          className={styles.iconContainer}
        >
          <img src={`/static/pricing/${icon}.svg`} />
        </div>
        <div
          id={`${id}-title-container`}
          data-pogr-id={`${pogrId || id}-title-container`}
          className={styles.title}
        >
          <div
            id={`${id}-bullet-style-for-text`}
            data-pogr-id={`${pogrId || id}-bullet-style-for-text`}
            className={styles.bigBullet}
          />
          <h3 id={`${id}-text`} data-pogr-id={`${pogrId || id}-text`}>
            {title}
          </h3>
        </div>
        <ul
          id={`${id}-ul-features`}
          data-pogr-id={`${pogrId || id}-ul-features`}
        >
          {features.map((f, i) => (
            <li
              key={`${f}-${i}`}
              id={`${id}-li-feature-${i}`}
              data-pogr-id={`${pogrId || id}-li-feature-${i}`}
            >
              <div
                id={`${id}-li-feature-${i}-content`}
                data-pogr-id={`${pogrId || id}-li-feature-${i}-content`}
                className={styles.listContent}
              >
                {f}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </animated.div>
  );
};

function PricingFeaturesSection({}: Props) {
  const [isVisible, setIsVisible] = useState(false);
  const anim1 = useSpring({
    from: {
      opacity: 0,
      x: -400,
    },
    to: {
      opacity: isVisible ? 1 : 0,
      x: isVisible ? 0 : -400,
    },
    delay: 200,
    config: config.default,
  });
  const anim2 = useSpring({
    from: {
      opacity: 0,
      x: -400,
    },
    to: {
      opacity: isVisible ? 1 : 0,
      x: isVisible ? 0 : -400,
    },
    delay: 400,
    config: config.default,
  });
  const anim3 = useSpring({
    from: {
      opacity: 0,
      x: -400,
    },
    to: {
      opacity: isVisible ? 1 : 0,
      x: isVisible ? 0 : -400,
    },
    delay: 600,
    config: config.default,
  });
  const anim4 = useSpring({
    from: {
      opacity: 0,
      x: -400,
    },
    to: {
      opacity: isVisible ? 1 : 0,
      x: isVisible ? 0 : -400,
    },
    delay: 800,
    config: config.default,
  });
  const anim5 = useSpring({
    from: {
      opacity: 0,
      x: -400,
    },
    to: {
      opacity: isVisible ? 1 : 0,
      x: isVisible ? 0 : -400,
    },
    delay: 1000,
    config: config.default,
  });
  const anims = [anim1, anim2, anim3, anim4, anim5];
  const handleChange = (visible: boolean) => {
    if (visible) {
      setIsVisible(true);
    }
  };
  return (
    <Section
      id={`${ID_PREFIX}-main`}
      pogrId={`${ID_PREFIX}-main`}
      style={{ backgroundColor: "#132439" }}
    >
      <div
        id={`${ID_PREFIX}-container`}
        data-pogr-id={`${ID_PREFIX}-container`}
        className={styles.container}
      >
        <div
          id={`${ID_PREFIX}-title-wrapper`}
          data-pogr-id={`${ID_PREFIX}-title-wrapper`}
          className={styles.titleWrapper}
        >
          <div
            id={`${ID_PREFIX}-image-container`}
            data-pogr-id={`${ID_PREFIX}-image-container`}
            className={styles.imgContainer}
          >
            <img
              id={`${ID_PREFIX}-image`}
              data-pogr-id={`${ID_PREFIX}-image`}
              src="/static/PogrBadge.png"
              alt="pogr-badge"
            />
          </div>
          <h2 id={`${ID_PREFIX}-title`} data-pogr-id={`${ID_PREFIX}-title`}>
            The POGR Package
          </h2>
        </div>
        <div
          id={`${ID_PREFIX}-subtitle-wrapper`}
          data-pogr-id={`${ID_PREFIX}-subtitle-wrapper`}
          className={styles.subtitleWrapper}
        >
          <p
            id={`${ID_PREFIX}-description`}
            data-pogr-id={`${ID_PREFIX}-description`}
            style={{ color: "#B7B7D4" }}
          >
            Below is a list of what’s included and what is to come.
          </p>
        </div>
        <div
          id={`${ID_PREFIX}-features-container`}
          data-pogr-id={`${ID_PREFIX}-features-container`}
          className={styles.featuresContainer}
        >
          <div
            id={`${ID_PREFIX}-pricing-list-wrapper`}
            data-pogr-id={`${ID_PREFIX}-pricing-list-wrapper`}
            className={styles.listWrapper}
          >
            <IntersectionObserverWrapper
              id={`${ID_PREFIX}-dev-intersection-observer`}
              pogrId={`${ID_PREFIX}-dev-intersection-observer`}
              onChange={handleChange}
            >
              {pricing_feature_data.map((item, i) => (
                <FeatureItem
                  key={`feature-item-${i}`}
                  id={`${ID_PREFIX}-feature-item-${i}`}
                  pogrId={`${ID_PREFIX}-feature-item-${i}`}
                  style={anims[i]}
                  {...item}
                />
              ))}
            </IntersectionObserverWrapper>
          </div>
        </div>
      </div>
      <div
        id={`${ID_PREFIX}-bottom-gradient`}
        data-pogr-id={`${ID_PREFIX}-bottom-gradient`}
        className={styles.bottomGradient}
      />
    </Section>
  );
}

export default PricingFeaturesSection;
