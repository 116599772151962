import Page from "../../components/layouts/page/Page";
import styles from "./LegalPageStyles.module.scss";

type Props = {};

function TOSPage({}: Props) {
  return (
    <Page>
      <div className={styles.wrapper}>
        <h2>Terms of Use</h2>
        <div className={styles.tableOfContent}>
          <p>
            These Customer Terms of Service (the “Customer Terms”) describe your
            rights and responsibilities when using the POGR Platform & Services
            (the “Services”).Please read them carefully. If you are a Customer
            (defined below), these Customer Terms govern your access and use of
            our Services. If you are being invited to our service as a User (as
            defined below),the User Terms of Service (the “User Terms”)
            supplement these Customer Terms and are incorporated herein by
            reference.
          </p>
          <p>
            These Customer Terms, the User Terms and any Order Form(s) (defined
            below) together form a binding agreement between Customer and POGR
            (the “Agreement”) and govern your access and use of the Services.
            “We,” “our” and “us” or “POGR” refers to POGR, Inc., a Delaware
            corporation.
          </p>
          <p>
            By using, accessing or browsing the Services, you are agreeing to be
            bound by these Customer Terms governing your use of the Services. If
            you are using the Services on behalf of an organization, you are
            agreeing to these Customer Terms for that organization and promising
            to us that you have the authority to bind that organization to these
            Customer Terms (in which event, "you" and "your" will refer to that
            organization). In order to use the Service, you must be legally
            permitted to accept these Customer Terms.
          </p>
        </div>
        <h3>1. Definitions</h3>
        <h4>Definitions & Meaning</h4>
        <p>
          The following are defined terms used in these Customer Terms and the
          User Terms:
        </p>
        <p>
          <b>a. “Account”</b> means your Services account or accounts through
          which your designated Account Administrator creates and manages one or
          more Workspaces.
        </p>
        <p>
          <b>b. “Account Administrator”</b> means the person authorized by you
          to create, organize and manage your Account and all of its Workspaces,
          including the ability to (i) create one or more Workspaces, (ii)
          invite, add and/or remove Users, (iii) create and manage User access
          permissions,including Workspace Administrators, (iv) upload, modify,
          download, delete, comment upon and otherwise manage Workspaces and
          their respective Libraries, and (iv) make all decisions and take any
          other actions necessary to manage your Account including termination
          of your Account and deletion of all data stored within your Account.
          If you are an individual agreeing to these Customer Terms on your
          personal behalf, you are the initial Account Administrator. If you are
          an individual agreeing to these Customer Terms on behalf of an entity,
          the entity you represent is the Owner of your Account and you are the
          initial Account Administrator. All Account Administrators are also
          considered to be a Collaborator on the Workspaces within the managed
          Account.
        </p>
        <p>
          <b>c. “Annual Plan”</b> as defined in Section 23.b. of these Customer
          Terms.
        </p>
        <p>
          <b>d. “Collaborator”</b> means one or more individuals authorized by a
          Workspace Administrator to access Libraries and/or Data within one or
          more Workspace(s), including the ability to organize,upload, modify,
          download, delete, comment upon and otherwise manage your Libraries and
          Data within such Workspace(s).
        </p>
        <p>
          <b>e. “Contractor”</b> means an individual authorized by a Workspace
          Administrator to access specified Data for the limited purpose of
          uploading, modifying, downloading, deleting, commenting upon and
          otherwise managing the specified Data.
        </p>
        <p>
          <b>f. “Customer”</b> means the person or organization registering your
          Account(s) and acting as the Owner of the Account(s) governed by the
          Agreement. If you register for an Account using your organization's
          email domain, your organization is Customer. If your Account(s) are
          being set up by someone who is not formally affiliated with an
          organization, “Customer” is the individual creating the Account.
          Customer is also referred to herein as “you”, “your” and terms of
          similar meaning.
        </p>
        <p>
          <b>g. “Data”</b> means your assets, files and associated metadata
          stored within your Account(s). This also includes assets, files and
          other information gathered through POGRs Platform & Services.
        </p>
        <p>
          <b>h. “Exploratory Plan”</b> as defined in Section 20.a. of these
          Customer Terms.
        </p>
        <p>
          <b>i. “Exploratory Term”</b> as defined in Section 20.a. of these
          Customer Terms.
        </p>
        <p>
          <b>j. “Feedback”</b> as defined in Section 16.b. of these Customer
          Terms.
        </p>
        <p>
          <b>k. “Fees”</b> as defined in Section 21 of these Customer Terms.
        </p>
        <p>
          <b>l. “Library”</b> or “Libraries” are groups of Data organized within
          a Workspace by any grouping mechanism chosen by the applicable User.
        </p>
        <p>
          <b>m. “Monthly Plan”</b> as defined in Section 23.a. of these Customer
          Terms.
        </p>
        <p>
          <b>n. “Order Form”</b> means an on-line or paper Order Form accepted
          (by click through) and/or signed (via electronic or paper signature)
          by you and us describing the Services purchased by you under the
          Agreement.
        </p>
        <p>
          <b>o. “Owner”</b> means you, as the owner of one or more Accounts.
        </p>
        <p>
          <b>p. “Paid Plan”</b> as defined in Section 20.d. of these Customer
          Terms.
        </p>
        <p>
          <b>q. “Personal Information”</b> has the meaning set forth in the
          Privacy Policy.
        </p>
        <p>
          <b>r. “Privacy Policy”</b> as defined in Section 3 below.
        </p>
        <p>
          <b>s. “Site”</b> as defined in Section 2 of these Customer Terms.
        </p>
        <p>
          <b>t. “Personal Plan”</b> as defined in Section 20.b. of these
          Customer Terms.
        </p>
        <p>
          <b>u. “Subscription Period”</b> means the period for which you elect
          to use Services under a Paid Plan as set forth in Section 23 of these
          Customer Terms. The Subscription Period for Personal Plans will be
          month-to-month. The Subscription Period for Exploratory Plans is the
          applicable Exploratory Term.
        </p>
        <p>
          <b>v. “Third Party Materials”</b> means any files, content, designs,
          models, data sets, project information, materials, documents, computer
          programs or similar material (including, without limitation, any
          modules and components, functions and features of a computer program),
          media, audio, images, names, email addresses, comments, notes, links
          and other content, data, information, applications and services made
          available to you and/or your Users by any third party (including any
          of your Users) through or in connection with the Services, the Site or
          any other website owned or operated by or for POGR. w. “Users” means,
          collectively or individually, as applicable, those persons authorized
          by you to access and/or manage your Account, Workspace(s) and/or
          Libraries within your Workspace(s),including Account Administrators,
          Workspace Administrators, Collaborators, Contractors and Viewers.
        </p>
        <p>
          <b>w. “Viewer”</b> means one or more individuals authorized by a
          Workspace Administrator to view and download all Data and comments
          within a Library and/or Workspace.
        </p>
        <p>
          <b>x. “Workspace”</b> means an on-line data storage space provided
          through the Services, through which you organize and store Data to be
          accessed and used by your Users as described in these CustomerTerms
          and the User Terms. Each Account will have one or more Workspaces.
        </p>
        <p>
          <b>y. “Workspace Administrator”</b> means a person authorized by your
          Account Administrator to create, organize and manage one or more of
          your Workspaces, including, within such Workspace(s), the ability to
          (i) invite, add and/or remove Users, (ii) create and manage User
          access permissions, (iii) upload,modify, download, delete, comment
          upon and otherwise manage Libraries and Data, and (iv) take any other
          actions to manage the authorized Workspace(s). If your Account
          Administrator has not authorized any Workspace administrator(s), your
          Account Administrator will also be your Workspace Administrator.
        </p>

        <h3>2. Changes to these Customer Terms</h3>
        <h4>Changes to Terms of Service</h4>

        <p>
          We reserve the right to change these Customer Terms from time-to-time.
          We will post the most current version of these{" "}
          <a href="https://pogr.gg/legal/tos" target="_blank">
            Customer Terms
          </a>
          . If we make material changes to these Customer Terms, we will notify
          you via the Services and/orby email to the address associated with
          your Account. If you do not accept the changes, you must stop using
          and cancel your account by emailing{" "}
          <a href="mailto:support@pogr.com">support@pogr.com</a>. Your continued
          use of our Services after we publish or send a notice about our
          changes to these Customer Terms means that you are consenting to the
          updated terms.
        </p>

        <h3>3. Privacy</h3>
        <h4>Collection of Information & Privacy Policy</h4>

        <p>
          To provide the Services, we collect certain information about you. We
          use and protect that information as described in our{" "}
          <a href="https://pogr.gg/legal/privacy" target="_blank">
            Privacy Policy
          </a>
          . You acknowledge your use of the Services is subject to our Privacy
          Policy and understand that it identifies how we collect, store, and
          use certain information.
        </p>

        <h3>4. Your Account</h3>
        <h4>User Account</h4>

        <p>
          To establish an Account, you will need to complete a registration form
          and designate a user ID and password. When registering your Account
          you must: (a) provide true, current and complete information about
          yourself on the registration form and (b) maintain such information so
          it continues to be true, current and complete. You may not share, loan
          or transfer your ID or password. If You become aware of any
          unauthorized use of the Services or your Account, or have any
          questions about your Account please contact POGR Support via{" "}
          <a href="mailto:support@pogr.com">support@pogr.com</a>
        </p>

        <h3>5. Your Data; Ownership of Data; Access, Retention and Security</h3>
        <h4>Ownership & Storage</h4>

        <p>
          a. You maintain responsibility for Data stored, accessed, downloaded
          or otherwise processed through use of the Services by you and/or your
          Users (collectively referred to herein as “yourData”).
        </p>
        <p>b. The Services permit you to share your data with your Users.</p>
        <p>
          c. You understand that your Users may be able to download, reproduce,
          manipulate, distribute, display, transmit, create derivative works of
          or otherwise process or communicate your Data.
        </p>
        <p>
          d. The Services may also permit your Users to upload Data to your
          Account. All Data stored in your Account, whether created by you, your
          Users or a third-party is considered your Data for purposes of this
          Agreement.
        </p>
        <p>
          e. You authorize POGR and Partners to keep a copy of your data to
          perform its operative needs.
        </p>

        <h4>Responsibility & Liablity</h4>
        <p>You acknowledge and agree that:</p>
        <p>
          (1) you will evaluate and bear all risks associated with your Data,
          including without limitation Data stored, accessed,downloaded or
          otherwise processed by your Users; and
        </p>
        <p>
          (2) under no circumstances will we be liable in any way for your Data
          or your use of the Services.
        </p>
        <p>
          We do not own your Data and have no right to use your Data other than
          as set forth in this Agreement and the Privacy Policy. Ownership and
          rights of use with respect to your Data as between you, your Users and
          third parties will be established by you, your Users and such third
          parties. We will have no liability regarding use or ownership of your
          Data by any person or entity and will not be responsible to you or any
          third-party with respect to your use, your Users’ use or any
          third-party’s use of your Data. You agree that your Data and your and
          your Users’ conduct in using the Services will comply with all
          applicable laws, rules and regulations and the terms of this
          Agreement.
        </p>

        <h4>Personnel Access & Warranties</h4>
        <p>Our personnel will not access your Data except</p>
        <p>
          (a) as part of providing, maintaining,securing or modifying the
          Services,
        </p>
        <p>
          (b) at your request or with your consent a part of addressing or
          preventing a service, support or technical issue, or
        </p>
        <p>
          (c) in connection with legal obligations or proceedings.Our use of
          your Personal Information will be as set forth in the Privacy Policy.
          You are encouraged to practice effective data retention and security
          practices with respect to your Data.
        </p>
        <p>
          (d) POGR will utilize its best efforts to securely maintain all data
          accessed & processed including internal security measures, Regular
          Security Audits and complying with applicable data laws.
        </p>

        <h3>6. Data Access; Account Administration</h3>
        <h4>Data Access</h4>

        <p>
          You are responsible for the administration of access to your Data by
          your Users including granting and terminating access to your Account.
          You acknowledge that in some cases, your Users may have the ability to
          download, copy or transfer your Data outside of the Services and
          suspending or terminating access will not delete or inhibit access to
          Data that was earlier downloaded or transferred.We have no control
          over and shall have no liability for any damages resulting from the
          use or misuse by any third-party of your Data that you choose to
          share, directly or indirectly, through the Services. If you choose to
          share your data or make your data available in your account or
          otherwise in connection with your and/or your users’ use of
          theservices, you do so at your own risk .
        </p>

        <h4>Account Administration</h4>
        <p>
          Your Account Administrator has ultimate control of your Account. The
          initial Account Administrator for each Customer is established upon
          initial registration of the Account. If Customer is an organization,
          Customer may re-assign the Account Administrator role within its
          Account. You acknowledge and agree that, in such cases, we may rely on
          representations by persons communicating from an email account using
          the Customer domain name claiming to be an authorized representative
          of Customer (a “Customer Representative”). We may attempt to confirm
          the authority of such a Customer Representative to assign a new
          Account Administrator, ncluding authentication by the existing Account
          Administrator, however, under no circumstances will we have liability
          to you for failure to do so or complying with instructions provided by
          the Customer Representative.
        </p>

        <h4>Access (as determined by user status)</h4>
        <ul>
          <li>
            <p>
              <b>Players (standard user)</b>: all Users have the same access and
              authority as the Account Administrator with regard to player-level
              related services. This includes (but not limited to) player
              profiles, visualization of data, data keeping, and other features
              offered through POGRs platform & service. These users can also
              grant access to their data to a third-party service of their
              choice. A standard users information is also generated to the
              associated developers within the platform & service.
            </p>
          </li>
          <li>
            <p>
              <b>Developers (advanced user)</b>: If you’re a developer then the
              Account Administrator has the authority to set User roles such as
              Workspace Administrator,Collaborator, Contractor and Viewer, and
              to set the permissions for each such role. This includes
              connecting & providing data access to multiple third party
              services as needed. All data generated on this level is owned by
              the Account Administrator, with all associated account data being
              copied from the standard user account and given full rights to
              this copy.
            </p>
          </li>
          <li>
            <p>
              <b>White Label (Partners & Sponsorship)</b>: Firms & Individuals
              operating with POGR under a White Label solution has the same
              access level as an advanced users, unless a separate agreement in
              writing has been executed.
            </p>
          </li>
        </ul>

        <h3>7. Data Storage; Compliance with Privacy Laws</h3>
        <h4>Data Storage</h4>

        <p>
          You acknowledge that we may use third-party service providers in
          connection with the Services, including without limitation the use of
          cloud computing service providers which may transmit, maintain and
          store your Data using third-party computers and equipment in locations
          around the globe.
        </p>

        <h4>Privacy Laws</h4>

        <p>
          You acknowledge and agree that you and your Users are responsible for
          compliance with all applicable privacy and data protection laws
          related to Personal Information contained within your Data, including
          any applicable requirements related to notice, consent, transfer
          (including cross-border transfer), disclosure, and use of Personal
          Information in connection with the Service,including as described in
          the Privacy Policy. Without limiting the foregoing, you will ensure
          that you and your Users have obtained consents, to the extent
          necessary, to provide Personal Information to be transferred to,
          collected, stored, used and otherwise processed by the Services. You
          further acknowledge that any data storage functionality associated
          with the Services is not intended for the storage of social security
          numbers, credit or debit card numbers, financial account numbers,
          driver’s license numbers, medical information, health insurance
          information, sensitive data about personal characteristics such as
          race, religion, or sexual orientation, or other personal data that may
          pose a risk of harm to the individual if improperly disclosed
          (collectively, “Sensitive Personal Information”). You agree not to,
          and to ensure that your Users do not, upload or otherwise submit any
          Sensitive Personal Information in connection with your and their use
          of the Services and further agree that we will have no responsibility
          or liability with respect to any such Sensitive Personal Information
          that is processed, transmitted, disclosed, or stored inconnection with
          your or your Users’ use of the Services. You will ensure that your
          Users are aware of the obligations contained in this Section.
        </p>

        <h3>8. Consent to Electronic Communications and Solicitation</h3>
        <h4>Consent to Communication and Solicitation</h4>

        <p>
          By registering for an Account, you understand and consent to us
          sending you (including via email) information regarding the Services,
          such as: (a) notices about your use of the Services, including notices
          of violations of use; (b) updates to theServices and new features or
          products; (c) administrative messages and other information; and
          (d)advertising, marketing, and other materials regarding our products
          and services. You may choose to opt-out of receiving advertising and
          marketing materials by unsubscribing from such communications
          following the instructions in the message. Notices emailed to you will
          be considered given and received when the email is sent. If you do not
          consent to receive notices regarding your use of the Services
          electronically, you must stop using the Services.
        </p>

        <h3>9. Availability of Third-Party Materials</h3>
        <h4>Third-Party Materials</h4>

        <p>
          Third-Party Materials may be made available to you, directly or
          indirectly, through the Services (including Third-Party Materials
          shared by your Users through their use of the Services or by any other
          means). In some cases, such Third-Party Materials may appear to be a
          feature or function within, or extension of, the Services. Accessing
          such Third-Party Materials may cause your computer or other device,
          without additional notice, to communicate with a third-party
          website—for example, for purposes of providing you with additional
          information, features and functionality. Such connectivity or access
          to third-party websites or Third-Party Materials is governed by the
          terms (including the disclaimers and notices) found on such sites or
          otherwise associated with the Third-Party Materials and access to and
          use of Third-Party Materials may require assent to separate terms
          and/or payment of additional fees. You agree that any viewing, use or
          access of Third-Party Materials by You or your users is at your sole
          risk. Under no circumstances will we be liable for any loss or damage
          caused by your or your Users’ viewing, use or reliance on Third-Party
          Materials. Any dealings between you and/or your Users and any
          Third-Party in connection with such Third-Party Materials,
          including,without limitation, such Third-Party’s privacy policies, use
          of Personal Information, delivery of and payment for goods and
          services, and any other terms associated with such dealings, are
          solely between you, your Users and such Third-Party. We may at any
          time, for any reason, modify or discontinue the availability of any
          Third-Party Materials.
        </p>

        <h3>10. Deleting Your Data from Inactive Sites</h3>
        <h4>Right to Delete</h4>

        <p>
          In addition to our rights to delete your Data upon expiration or
          termination of this Agreement under Section 14 (Termination), we have
          the right (but not the obligation) to delete inactive sites or
          accounts or purge related content (and all backups thereof),without
          further notice and without liability for deletion or failure to store
          such content. We shall have no responsibility or liability for
          deletion based on your settings or your or your Users’ actions or
          inactions for any failure to delete your Data.
        </p>

        <h3>11. Copyright Complaint and Removal Policy</h3>
        <h4>Compliance with copyright law</h4>

        <p>
          We respect the intellectual property of others and will respond to
          notices of alleged copyright infringement that comply with applicable
          law. We reserve the right to delete or disable your Data alleged to
          violate copyright laws or these Customer Terms and reserve the right
          to terminate the Account(s) of violators. If you believe there has
          been a violation of your intellectual property rights, please contact
          us at <a href="mailto:support@pogr.com">support@pogr.com</a>.
        </p>

        <h3>12. Confidential Information</h3>
        <h4>Confidential Information</h4>

        <p>
          During your use of the Services, we may share with you information
          that is confidential, sensitive or should be kept secret. For example,
          if we tell you about our product roadmaps, product designs and
          architecture, technology and technical information, provide you with
          security audit reviews, business and marketing plans, or share with
          you our business processes, these should always be considered
          confidential to us. Similarly, we agree that your Data, credit
          card/banking information and information contained in your Account is
          confidential to you. Also, if either of us provide any documents to
          the other that are labeled “confidential” (or something similar), or
          provide information (either in writing or verbal) that is of a type
          that a reasonable person should understand to be confidential, such
          information is to be treated as confidential information. However, if
          you tell us information that: (a) we already know at the time you tell
          us; (b) was told to us by a third-party who had the right to tell us;
          (c) is generally available to the public; or (d) was independently
          developed by us without using any of your confidential information,
          then that information will not be considered confidential. The same
          goes for information that we tell you that falls into any of these
          categories. Lastly,we both agree that: (i) we will treat each other’s
          information with the same degree of care that we treat our own
          confidential information; (ii) will use each other’s confidential
          information only in connection with these Customer Terms and provision
          or use of the Services, as applicable; (iii) only share the
          information with others who have a need to know and who have agreed in
          writing to treat it as confidential (as we’ve outlined in this
          section); and (iv) not share the information with any third-party
          except as allowed in these Terms or through the Services. Your
          decision to share your confidential information with your Users or any
          other third-party through your use of the Services will be considered
          disclosure by you, not us. Confidential information will always remain
          the property of its owner.
        </p>

        <h3>13. Acceptable Use Policy</h3>
        <h4>Acceptable Use</h4>

        <p>
          You agree you and your Users will not, nor will you or they encourage
          others or assist others to, harm the Services or use the Services to
          harm others. For example, you and your Users must not use the Services
          to harm, threaten, or harass another person, organization orPOGR
          and/or to build a similar service or website. You and your Users must
          not: (a) damage, disable,overburden, or impair the Services (or any
          network connected to the Services); (b) resell or redistribute the
          Services or any part of it; (c) use any unauthorized means to modify,
          reroute, or gain access to theServices or attempt to carry out these
          activities; (d) use any automated process or service (such as a bot,
          aspider, or periodic caching of information stored by us) to access or
          use the Services; (e) use the Services Beyond the features allocation
          and amounts provided in your Services plan; (f) remove, alter, or
          obscure any copyright, trademark, confidentiality or other proprietary
          notices, labels, or marks from or within theServices or modify,
          translate, adapt, arrange, or create derivative works based on the
          Services; (g)decompile, disassemble or otherwise reverse engineer the
          Services, or determine or attempt to determineany source code,
          algorithms, methods, or techniques used or embodied in the Services;
          (h) utilize anyequipment, device, software, or other means to (or
          designed to) circumvent or remove any form oftechnical protection used
          by us in connection with the Services; (i) use the Services to, and/or
          cause us to,violate any law or distribute any malware or malicious
          computer code, programs or relatedcontent; (j) use the Services to
          communicate any message or material that is defamatory,
          harassing,libelous, threatening, or obscene; (k) use the Services in a
          way that violates or infringes upon theintellectual property rights or
          the privacy or publicity rights of any person or entity; (l)
          distribute, post,share information, data or related content illegally
          or without permission; (m) use the Services todisseminate or promote
          terrorism or terrorist activities; or (n) use the Services to
          disseminate material thatmay infringe upon legally protected social
          rights and freedoms of a person(s) (collectively, ‘humanrights’)
          and/or as defined by domestic and public international laws and/or
          other legal norms.
        </p>

        <h4>Right to Protect The Service</h4>
        <p>
          As part of our efforts to protect the Services, protect our customers,
          or to stop you and/or your Users from breaching these Customer Terms,
          we retain the right to block or otherwise prevent delivery of any type
          of file, email or other communication to or from the Services. We also
          reserve the right to deactivate, change and/or require you to change
          your Account user ID or password, and those of your Users, as well as
          any anyURLs or links, you may obtain through the Services. You also
          agree that if you learn of any unauthorized or unacceptable use of any
          Account, Data or the Services, you will promptly contact POGR
          Supportsupport@POGR.com and take all reasonable steps to cooperate
          with US and assist in the termination of such use.
        </p>

        <h3>14. Suspension and Termination of Services</h3>
        <h4>Right to Terminate or Suspend Access</h4>

        <p>
          We reserve the right to suspend or terminate your and/or any of your
          User(s)’ access to the Services at any time in our sole discretion or
          disable any or your Data if (a) you or any of your Users are in breach
          of these Customer Terms; or b) your or any of yourUsers’ use of the
          Services could cause a risk of harm or loss to us or our other
          customers; or c) we decline to renew your Services at the end of any
          Subscription Period. When reasonable and as permitted by law,we will
          provide you reasonable advance notice of this change as well as an
          opportunity to correct any actions that led to our decision. We will
          not be able to provide this advance notice if you or any of yourUsers
          are in material breach of these Customer Terms (or the User Terms), or
          if such notice would lead to civil or criminal liability for us, or if
          providing notice would compromise our ability to provide theServices
          to our other customers. For the avoidance of doubt, we may still make
          a determination that we do not want to continue offering you access to
          the Services at any time for any or no reason. You understand that if
          your account is suspended or terminated, you may no longer have access
          to your Data That is stored in your Account. Any day collected &
          processed up to that point through our service is copied and retained
          for POGRs business needs.
        </p>

        <h4>Access to Data after Termination</h4>
        <p>
          Upon termination you may request access to your Data, which we will
          make available, except in cases where we have terminated your Account
          due to your or your User(s) violation of these Customer Terms (or the
          User Terms). You must make such a request within fourteen(14) days
          following termination otherwise, any of your Data stored in your
          Account may not be retrievable and we will have no obligation to
          maintain your Data stored in your account after this fourteen(14) day
          period. Note, however, that any of your Data that you or your Users
          choose to delete, at any time, may no longer be accessible to us, or
          you, in your Account. you are solely responsible for your and your
          users’ deletion of your data and we will have no liability to you for
          your or your users’ inability to access data following its deletion.
        </p>

        <h3>15. Updates to the Services</h3>
        <h4>Updating of Platform & Service</h4>
        <p>
          We may make necessary deployments of changes, updates or enhancements
          to the Services at any time. We may also add or remove functionalities
          or features, or we may suspend or stop the Services altogether.
        </p>

        <h3>16. POGR Proprietary Rights; Feedback</h3>
        <h4>Proprietary Rights</h4>
        <p>
          You acknowledge and agree that POGR and its licensors own all right,
          title, and interest (including, without limitation, patents,
          copyrights, trademarks, trade secrets, and all other intellectual
          property rights) in and to the Services and any and all information,
          data or materials provided or used in connection with, or generated
          by, the Services (excluding your Data or your or yourUsers’
          Third-Party Materials). You agree not, and to cause your Users not to,
          take any action to jeopardize, encumber, limit, or interfere in any
          manner with POGR’s or its licensors’ ownership and rights with respect
          thereto. We do not grant you or your Users any right to use our
          trademarks, trade names, or logos. You and your Users have only the
          limited rights to use the Services as are expressly granted to you
          under these Customer Terms and no other rights are granted or
          conveyed, or shall be deemed to be granted conveyed, whether by
          implication, estoppel, or otherwise.
        </p>
        <h4>Feedback</h4>
        <p>
          We may seek, and/or you and your Users may provide suggestions or
          recommendations concerning potential changes to or improvements of the
          Services, including without limitation, new features or functionality
          relating thereto, or any comments, questions, suggestions, or thelike
          ("Feedback"). We are free to use such Feedback and you and your Users
          hereby assign to us on your and your User(s)’ behalf, and on behalf of
          any of your and/or your User(s)’ employees, contractors and/oragents,
          all right, title, and interest in, and we are free to use, without any
          attribution or compensation to any party, any ideas, know-how,
          concepts, techniques, or other intellectual property rights contained
          in the Feedback.
        </p>

        <h3>17. Indemnification and Warranties</h3>
        <h4>Indemnification</h4>
        <p>
          You shall, at your sole expense and to the fullest extent permitted by
          law, indemnify, defend (at our request), and hold us harmless against
          any and all losses, liabilities,expenses (including reasonable
          attorneys’ fees) suffered or incurred by us by reason of any claim,
          suit or proceeding (“Claim”) arising out of or in connection with: (1)
          your Data or you or any of your Users’ use of your Data, including,
          without limitation, any assertion that your Data or the use thereof
          may infringe any copyright, trademark, or other intellectual property
          or other rights of any individual or entity, or are misappropriation
          of any individual or entity’s trade secret, or contain any libelous,
          defamatory,disparagi ng, pornographic, or obscene materials or use
          thereof caused death or bodily injury or damage to the real or
          tangible property of any third party; (2) any breach of or failure by
          you or your Users to comply with these Customer Terms; or (3) use of
          the Services by you, your Users or anyone else who accesses the
          Services through you. If requested by us to defend a Claim, you will
          not agree to a settlement without our prior written consent, and you
          shall have the right to participate, at your own expense, in the
          defense of any Claim with counsel of your choosing.
        </p>
        <h4>Warranties</h4>
        <p>
          You acknowledge rights to submit, develop and use your Data in
          connection with the Services; (2) your Data does not infringe or
          misappropriate any intellectual property or proprietary right of any
          third party or violate any applicable laws, rules or regulations; (3)
          your Data is not subject to any restriction on disclosure,
          transfer,download, export or re-export under any applicable law, rule
          or regulation; and (4) any information you or your Users provide in
          connection with your and your Users’ registration for, or use of, the
          Services is and shall remain true, accurate, and complete, and that
          you and your Users will maintain and update such information
          regularly.
        </p>

        <h3>
          18. DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITIES; RELEASE;
          BENEFIT OF THE BARGAIN
        </h3>
        <h4>Warranty Disclaimer</h4>
        <p>
          The services are provided “as is” and “as available.” we make no, and
          hereby disclaim all, representations, warranties, or conditions of any
          kind, whether express, implied (either in fact or by operation of
          law), or statutory, with respect to the services,including, without
          limitation, all warranties or conditions of merchantability, fitness
          for a particular purpose, title and noninfringement, and all
          warranties that may arise from course of dealing, course of
          performance, or usage of trade. your and your users use of the
          services is at your own and their own discretion and risk. we do not
          warrant that any use of or access to the services will be
          error-free,complete, secure or that your data will not be otherwise
          lost or damaged or meet your requirements or expectations; that
          operation or availability of the services will be uninterrupted; or
          that errors or failures will be corrected or remedied. we do not
          warrant that the services will perform in any particular manner.
          without limitation of the generality of the foregoing, you and your
          users acknowledge and agree that you and your users are solely
          responsible for (and we assume no responsibility and will have no
          liability of any kind for) (1) the decisions that you and your users
          may make regarding the services; (2) your and your users’ use of the
          services including any content, data, information, or other material
          accessed by you or your users in connection with the services
          including, without limitation, impact to your or their computer
          systems or loss of data; or (3) any effects on your or their business
          that may result from such use. we make no warranties to any third
          party. we do not represent or warrant that the services are or will be
          appropriate or available for use in any particular jurisdiction. this
          section will be enforceable to the maximum extent allowed by
          applicable law. no information or advice (whether written, oral or
          otherwise) provided by us or our representatives will create any
          warranty or in any way affect the disclaimers of warranty or
          limitations of liability expressly provided in these customer terms.
        </p>
        <h4>Functionality Limitations</h4>
        <p>
          The services are not a substitute for your or your users’ judgment
          (including professional judgment) or independent testing, design,
          estimation or analysis, as applicable. due to the large variety of
          potential applications for the services, they have not been tested in
          all situations under which they may be used and may not achieve the
          results you or your users desire. further to the other limitation on
          our liability set forth in these customer terms, we shall not be
          liable in any manner whatsoever for any results or output obtained or
          otherwise viewed through the services or any materials developed by
          you or your users in connection with your respective use of the
          services. you are responsible for the supervision, management and
          control of use of the services by your users. this responsibility
          includes, but is not limited to, the determination of appropriate uses
          for the services and the selection of the services and other programs
          to achieve your and your users’ intended results. you are also
          responsible for establishing the adequacy of independent procedures
          for testing the reliability, accuracy and completeness of the
          services, your data and/or materials developed by you and your users
          in connection with your respective use of the services (if any),
          including the development, storage, manipulation, processing and other
          uses of your data by you and your users using the services. there are
          no service level agreements made in connection with the services
          unless otherwise specifically agreed to by us, in writing.
        </p>
        <h4>Limitation of Liability</h4>
        <p>
          To the maximum extent allowed by applicable law and notwithstanding
          any failure of essential purpose of any limited remedy or limitation
          of liability, (1) in no event will we be liable to you or your users
          hereunder for special, indirect, consequential, or any other damages
          of like kind whatsoever (however caused and regardless of the theory
          of liability, whether derived from contract, tort (including without
          limitation negligence) or otherwise), including without limitation
          loss of profits, loss of revenue, loss of use,loss of data, business
          interruption, cost of procurement of substitute goods or services or
          other cover, or any other similar commercial or economic loss of any
          kind, even if advised of the possibility thereof, norwill we be liable
          for any damages whatsoever resulting from a force majeure or an act of
          a third party; and (2) our total cumulative collective liability for
          all costs, losses or damages from all claims,actions or suits however
          caused or arising from or in relation to the services shall not exceed
          (a) all amounts paid or due from you for access to the services giving
          rise to the claim during the six (6) months immediately preceding the
          claim (no matter when payments were actually made), or (b) one hundred
          dollars ($100), whichever is greater. countries where the above types
          of exclusions and limitations aren't allowed, we’re responsible to you
          and your users, in the aggregate, only for losses and damages that are
          a reasonably foreseeable result of our failure to use reasonable skill
          and care or our breach of our contract with you. nothing in these
          custoer terms affects consumer rights that cannot by law be waived or
          limited by any contract or agreement.
        </p>
        <h4>Basis of the Bargain</h4>
        <p>
          The parties agree that releases, waivers, warranty
          disclaimers,limitations of liability and indemnities in these Customer
          Terms are a fundamental basis of the bargain between you, your Users
          and us, and are a material part of the consideration received by us
          for the provision of the Services under these Customer Terms, and we
          would not have entered into theseCustomer Terms and provided the
          Services in the absence of such releases, waivers, warranty
          disclaimers,limitations of liability and indemnities.
        </p>

        <h3>19. Dispute Resolution and Governing Law</h3>
        <h4>Regulatory requirements</h4>
        <p>
          You and your Users must comply with all domestic and international
          export laws and regulations that apply to your and their respective
          use of the Services. These laws include restrictions on destinations,
          end users, and end use.
        </p>
        <h4>Choice of Venue</h4>
        <p>
          These Customer Terms and the Agreement will be governed by the laws of
          the State of California, U.S.A. regardless of conflict of laws
          principles. The United Nations Convention on Contracts for the
          International Sale of Goods, the Uniform Commercial Code, the Uniform
          Computer Information Transactions Act, and any law effectuating these
          conventions do not apply to these Customer Terms. We both agree that
          all of these claims can only be litigated in the federal or state
          courts in Los Angeles, California, USA and we each agree to personal
          jurisdiction in those courts. However, you agree that we can apply for
          injunctive remedies in any jurisdiction.
        </p>
        <h4>Agreement to resolve on an individual basis</h4>
        <p>
          To the extent that the following provision is not in conflict with
          applicable law, you and your Users may only resolve disputes with us
          on an individual basis and may not bring a claim or proceed in a group
          arbitration proceeding as a plaintiff or a class member in a class,
          consolidated, or representative action.
        </p>

        <h3>20. Freemium Plans; Beta Services; Paid Plans</h3>
        <h4>Freemium Plan</h4>
        <p>
          Our Freemium Plan is the free version of our Services with many of the
          features of the Paid Plan Services plan (the “Freemium Plan”). Account
          and Workspace registration and structuring is similar to the Paid
          Plan, but storage space per User as well as the aggregate number of
          Users that can be added to a freemium Plan are limited in the manner
          communicated to you upon registration for your freemium Plan. Further,
          under the freemium Plan, all Users have full access to view, download,
          manipulate, manage and delete all of your Data to the same extent as
          the Account Administrator with the exception of being able to
          terminate the Account.
        </p>
        <h4>Beta Services</h4>
        <p>
          We will also offer new features and services under development to you
          and your Users which will be labeled as “beta services” (the “Beta
          Services”). You and Your Users acknowledge and agree that Beta
          Services are offered “As Is” with no warranty whatsoever and that we
          will have no liability to you or your Users whatsoever in connection
          with your respective use of any BetaServices.
        </p>
        <h4>Paid Plans (POGR Premium & White Label Solutions)</h4>
        <p>
          Our paid Services plans (“Paid Plans”) offer a variety of Services
          enhancements including increased storage, Users and the ability to set
          a variety of User roles and permissions. Subscription and payment
          terms for our Paid Plans are set forth below with pricing and features
          communicated on our Site and at the time of registering your Paid Plan
          Account. Any bespoke contracts . All use of freemium, Beta Services
          and Paid Plans by you and your Users are subject to these Customer
          Terms including any additional terms found within any White Label
          Solutions or Bespoke Solutions.
        </p>

        <h3>21. Fees</h3>
        <h4>Fees</h4>
        <p>
          If you subscribe for a Paid Plan, you agree to pay the fees as quoted
          to you when you register for the Paid Plan Services (“Fees”). We may
          calculate taxes payable by you based on the billing information that
          you provide us at the time of purchase. You are responsible for all
          charges related to using the Paid Plan Services (for example, data
          charges and currency exchange settlements). All Fees are payable in
          U.S. dollars at the amount quoted upon registration. We reserve the
          right to change our prices at any time, however, Paid Plan pricing
          increases will not go into effect until the next renewal of your
          Subscription Period following the pricing increase. After your
          Subscription Period ends, your use of the Services will be charged at
          the then-current Fee(s). If you don't agree to these changes, you must
          stop using the Services and cancel via email to{" "}
          <a href="mailto:support@pogr.com">support@pogr.com</a> (with
          cancellation confirmation from a POGR representative).
        </p>
        <h4>Cancellation of Subscription</h4>
        <p>
          If you cancel, your Services end at the end of your current
          Subscription Period and no refunds for previously paid Services will
          be issued. If you do not cancel in accordance with these Customer
          Terms, the Subscription Period for your Services will automatically
          renew at the then-current price and the term length for the next
          Subscription Period will be the same as the term of the expiring
          Subscription Period. We will charge your credit card on file with us
          on the renewal date of each Subscription Period.
        </p>

        <h3>22. Billing/Payment</h3>
        <h4>Billing and Payment</h4>
        <p>
          If you select a Paid Plan, you must provide us with current, complete,
          accurate and authorized payment method information (e.g. credit card
          information). You authorize us to charge your provided payment method
          for the Services you have selected and for any paid feature(s) that
          you choose. We may bill: (a) in advance; (b) at the time of purchase;
          (c) shortly after purchase; or (d) for automatically renewing
          Services, on a recurring basis. To the extent we have not received
          your payment, in order to bring your account up to date, we may bill
          you simultaneously for both past due and current amounts. If you do
          not cancel your Account, we may automatically renew your Service(s)
          and charge you for any renewal term. You understand that failure to
          pay any charges or fees may result in the suspension or cancellation
          of your Services.
        </p>

        <h3>23. Subscription Period</h3>
        <h4>Subscription Options</h4>
        <p>
          You may elect one of the following Paid Plan Subscription Periods and
          billing options (please note that there might be only one of these
          options available depending on the Services purchased):
        </p>
        <ul>
          <li>
            <p>
              <b>A monthly Paid Plan (“Monthly Plan”)</b>: The subscription
              period for the Monthly Plan will be for one month beginning on the
              day of the month you initially register your Paid Plan Account and
              will automatically renew on the same day of each month (without
              the need to submit or execute a renewal order form) unless you
              cancel your Monthly Plan at least three (3) business days prior to
              the applicable renewal date. You will be billed on or about the
              same day each month until you cancel your Paid Plan.
            </p>
          </li>
          <li>
            <p>
              <b>An annual Paid Plan (“Annual Plan”)</b>: The subscription
              period for the Annual Plan will be for one year beginning on the
              day of the year you initially register your Account and will
              automatically renew on each anniversary of such date (without the
              need submit or execute a renewal Order Form) for additional
              periods equal to one (1) year unless you cancel at least three (3)
              business days prior to your renewal date. You will be billed
              annually on or about the same day each year until such time that
              you cancel your Paid Plan. Note that under the Annual Plan you
              will not be permitted to cancel, reduce the number of seats, or
              downgrade the Services you have selected until the anniversary
              date. Be aware that you are committing to a one-year plan; if you
              are not certain, we recommend choosing the Monthly Plan.
            </p>
          </li>
        </ul>
        <h4>Upgrading plans</h4>
        <p>
          If you select the Monthly Plan, you can switch to an Annual Plan at
          any time. If you select an Annual Plan, you may not change to a
          Monthly Plan until the end of the one-year term of your Annual Plan.
        </p>
        <h4>Right to Cancelation</h4>
        <p>
          Where required by law, you may have a legal right to cancel your
          Services subscription for a Paid Plan during the prescribed time frame
          of your initial subscription period. If you are entitled to this right
          by law, your cancellation request will be processed within three (3)
          business days, and you will receive a prorated refund of any unused
          prepaid Fees.
        </p>

        <h3>24. General Terms</h3>
        <h4>Severability; Entire Agreement</h4>
        <p>
          These Customer Terms apply to the maximum extent permitted by
          applicable law. If a court holds that we cannot enforce a part of
          these Customer Terms as written, you and we will replace those terms
          with similar terms to the extent enforceable under the applicable law,
          but the rest of these Customer Terms will remain in effect. The
          Agreement (including these Customer Terms) is the entire contract
          between you and us regarding your and your Users’ use of the Services
          and supersedes any prior contract or oral or written statements
          regarding your and your Users’ use of the Services, provided that your
          Users’ use of the Services are also subject to the User Terms by and
          between us and your Users as in effect from time-to-time.
        </p>
        <h4>Assignment and Transfer</h4>
        <p>
          We may assign, transfer, or otherwise dispose our rights and
          obligations under this Agreement, in whole or in part, at any time
          without notice. You may not assign this Agreement or transfer any
          rights to use the Service, unless we provide written consent for you
          to do so.
        </p>
        <h4>Independent Contractors; No Third-Party Beneficiaries</h4>
        <p>
          For personnel or firms who utilize our developer level platforms &
          services, POGR and you are not legal partners or agents; instead, our
          relationship is that of independent contractors. The Agreement is
          solely for your and our benefit. It is not for the benefit of any
          other person, except for permitted successors. The only time this
          provision is overridden is if another agreement is in place between
          POGR & the signer of this other agreement.
        </p>
        <h4>Waiver</h4>
        <p>
          The failure of either of us to insist upon or enforce strict
          performance of any of the provisions of the Agreement or to exercise
          any rights or remedies under the Agreement will not be construed as a
          waiver or relinquishment to any extent of such right to assert or rely
          upon any such provision, right or remedy in that or any other
          instance; rather, the same will remain in full force and effect,
          unless expressly waived in writing.
        </p>
        <h4>Government Terms</h4>
        <p>
          If Customer is a U.S. government entity or if this Agreement otherwise
          becomes subject to the Federal Acquisition Regulations (FAR), Customer
          acknowledges that elements of the Services constitute software and
          documentation and are provided as “Commercial Items” as defined at 48
          C.F.R. 2.101, and are being licensed to U.S. government User as
          commercial computer software subject to the restricted rights
          described in 48 C.F.R. 2.101 and 12.212.
        </p>
      </div>
    </Page>
  );
}

export default TOSPage;
